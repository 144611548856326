import React from 'react'
import { useDelete, useFetch } from '../../../components/component-items/helpers'
import { BackgroundLayer } from './schedule/styles'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { TableCell } from '../../../components/component-items/datatable'
import { globalState } from '../../../store'
import { StatusBoxStyled } from '../../../components/component-items/status-box'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStars, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons'
import { RecurrenceTaskCategory, RecurrenceTaskName } from '../../../enums'

export const ScheduleList = () => {
  const {
    state: { csrf },
  } = globalState()

  const url = `/api/core/recurring-tasks/?category=${RecurrenceTaskCategory.CategoryReporting}`
  const { response: resp } = useFetch(url, {})

  const schedules = resp || []
  const total = resp?.length || 0

  const getReportName = (taskName: string) => {
    // convert above to switch
    switch (taskName) {
      case RecurrenceTaskName.InventoryReport:
        return 'Inventory Report'
      case RecurrenceTaskName.InventorySnapshot:
        return 'Inventory Snapshot'
      case RecurrenceTaskName.OrderReport:
        return 'Order Detail Report'
      case RecurrenceTaskName.ShipmentReport:
        return 'Shipment Detail Report'
      default:
        return 'Unknown Report'
    }
  }

  return (
    <BackgroundLayer style={{ display: 'block', padding: '1em', minHeight: 200 }}>
      <GlobalStyles.CardHeader style={{ padding: '0 12px' }}>
        <GlobalStyles.CardTitle>
          <h3>
            <FontAwesomeIcon icon={faStars} style={{ color: '#001682', marginRight: '.5em' }} />
            Scheduled Reports<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar></GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 100, width: '100%' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Recurrence</span>
              </th>
              <th>
                <span className="text center">Report</span>
              </th>
              <th>
                <span className="text center">Date Range</span>
              </th>
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {schedules.map(({ id, recurrenceDescription, taskName, taskKwargs: { dateRange } }: any) => (
              <GlobalStyles.TableRow key={id}>
                <TableCell>{recurrenceDescription}</TableCell>
                <TableCell
                  input={
                    <StatusBoxStyled className={'royalshine'} style={{ margin: 'auto', width: 'fit-content' }}>
                      {getReportName(taskName)}
                    </StatusBoxStyled>
                  }
                />
                <TableCell
                  center
                  input={
                    <StatusBoxStyled
                      className={'silver'}
                      style={{ width: 80, margin: 'auto', textTransform: 'capitalize' }}
                    >
                      {dateRange ? dateRange?.replace(/_/g, ' ') : 'Today'}
                    </StatusBoxStyled>
                  }
                />
                <TableCell center>
                  <div className="dropdown__container">
                    <button onClick={() => useDelete(`/api/core/recurring-tasks/${id}/`, csrf)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                </TableCell>
              </GlobalStyles.TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
    </BackgroundLayer>
  )
}
