import React from 'react'
import { StatusBarStyled } from '../../components/component-items/status-bar'
import { useFetch } from '../../components/component-items/helpers'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'
import { faCheckDouble } from '@fortawesome/pro-regular-svg-icons'
import { faFileImport, faWandMagicSparkles } from '@fortawesome/pro-duotone-svg-icons'
import { globalState } from '../../store'
import { theme as customTheme } from '../../theme/ShippingTreeTheme'

export const StatusBar = () => {
  const {
    state: { userIsWarehouse, actAs },
  } = globalState()

  const company_details_url = userIsWarehouse && !actAs ? '' : `/api/core/automation/summary/`
  const { response: resp, loaded } = useFetch(company_details_url, {})
  const company = loaded ? resp.companyName : ''
  const lastSuccessfulTransaction = (resp?.lastRun || '')?.split('T')[0] || 'Never'
  const successfulTransactions = loaded ? resp.executionCounts : 0
  const color = successfulTransactions > 0 ? 'green' : 'blue'

  return (
    <div className="row">
      <div className="col">
        <StatusBarStyled>
          <div className="header-item">
            <div className="status-icon">
              <FontAwesomeIcon icon={faCheckDouble} style={{ color: '#51e2d3' }} />
            </div>
            <div className="status-text">
              <div>
                Last Successful Action <strong>{lastSuccessfulTransaction}</strong>
              </div>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="header-item center">
            <div className="status-icon">
              <FontAwesomeIcon icon={faWandMagicSparkles} style={{ color: customTheme.colors.freshEggplant }} />
            </div>
            <div className="status-text">Automations {company ? `for ${company}` : ''}</div>
          </div>
          <div className="border-line"></div>
          <div className="header-item right">
            <div className="status-text">
              Successful Triggers: <strong>{successfulTransactions}</strong>
            </div>
            <div className="status-icon" style={{ marginLeft: '1em' }}>
              <FontAwesomeIcon icon={faFileImport} style={{ color: color }} />
            </div>
          </div>
        </StatusBarStyled>
      </div>
    </div>
  )
}
