import React, { useState, useRef, useEffect, FunctionComponent } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useFetch } from '../../component-items/helpers'

// Components
import { Styled } from './styles'
import CardLoading from '../../component-items/loading-popover'
import GenericModal from '../../component-items/modal'
import GlobalStyles from '../../component-items/styles'
import { OrderStatusBox } from '../../component-items/status-box'
import { AsnListFiltered } from '../../../pages/asns/asn/asn-list'
import { ProductRow } from '../../../pages/products'
import { TableCell } from '../../component-items/datatable'
import { SearchBox } from '../../component-items/search'

// Types
import { OrderProps, SearchResultItemProps } from './types'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'

export const SearchResultItem: FunctionComponent<SearchResultItemProps> = ({
  url,
  icon,
  image,
  title,
  desc,
  className,
  children,
}) => (
  <Styled.SearchResultItem href={url || '#'}>
    <div className={`search__item-icon ${className}`}>
      {image ? (
        <img src={image}></img>
      ) : (
        <i>
          <FontAwesomeIcon icon={icon} />
        </i>
      )}
    </div>
    <div className="search__item-wrapper">
      <div className="search__item-title">{title}</div>
      <div className="search__item-desc">{desc}</div>
      {children}
    </div>
  </Styled.SearchResultItem>
)

export const Search = () => {
  const [isOpen, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')

  let { pathname } = useLocation()
  useEffect(() => {
    setSearch('')
    setOpen(false)
  }, [pathname])

  const urlOrders = isOpen && search ? '/api/order/order_list/' : null
  const urlAsns = isOpen && search ? '/api/inventory/asn/' : null
  const urlProductVariants = isOpen && search ? '/api/product/variant/' : null

  // Input element gets focused on open
  const inputElement = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus()
      inputElement.current.select()
    }
  }, [isOpen])

  var {
    response: respOrders,
    loaded: loadedOrders,
    error: errorOrders,
    placeholder: ordersPlaceholder,
  } = useFetch(`${urlOrders}?q=${encodeURIComponent(search)}&search_all_warehouses=True`, { redirect: 'follow' })

  var {
    response: respAsns,
    loaded: loadedAsns,
    error: errorAsns,
    placeholder: asnsPlaceholder,
  } = useFetch(`${urlAsns}?q=${encodeURIComponent(search)}`, { redirect: 'follow' })

  var {
    response: respVariants,
    loaded: loadedVariants,
    error: errorVariants,
    placeholder: productVariantsPlaceholder,
  } = useFetch(`${urlProductVariants}?q=${encodeURIComponent(search)}&deleted=false&duplicate=false`, {
    redirect: 'follow',
  })

  useEffect(() => {
    setLoading(!loadedVariants || !loadedAsns || !loadedOrders)
  }, [loadedVariants, loadedAsns, loadedOrders])

  var orders: OrderProps[] = loadedOrders ? (respOrders as any)?.orders || [] : []
  var asns = loadedAsns ? (respAsns as any)?.asns || [] : []
  var variants = loadedVariants ? (respVariants as any)?.results || [] : []

  return (
    <Styled.Search>
      <Styled.HeaderButton className={isOpen ? 'active' : ''} onClick={() => setOpen(!isOpen)} aria-haspopup="true">
        <span className={'header__topbar-icon'}>
          <i>
            <FontAwesomeIcon icon={faSearch} />
          </i>
        </span>
      </Styled.HeaderButton>
      <GenericModal
        heading={'Search'}
        show={isOpen}
        size="xl"
        onHide={() => {
          setSearch('')
          setOpen(false)
        }}
        buttons={
          <GlobalStyles.Button
            onClick={() => {
              setSearch('')
              setOpen(false)
            }}
          >
            Close
          </GlobalStyles.Button>
        }
      >
        <div style={{ margin: '1em' }}>
          <SearchBox {...{ search, setSearch, loading, setLoading, inputElement }} updateURL />
          <GlobalStyles.FullPageCard style={{ marginTop: 10 }}>
            <GlobalStyles.CardHeader>
              <GlobalStyles.CardTitle>
                <h3>
                  Order Results<small>{orders.length} Total</small>
                </h3>
              </GlobalStyles.CardTitle>
            </GlobalStyles.CardHeader>
            <div style={{ minHeight: 100, maxHeight: 500, overflow: 'scroll' }}>
              <GlobalStyles.DataTable>
                <thead>
                  <tr>
                    <th>
                      <span className="text center">Order Number</span>
                    </th>
                    <th>
                      <span className="text center">Created</span>
                    </th>
                    <th>
                      <span className="text">Address</span>
                    </th>
                    <th>
                      <span className="text center">Order Items</span>
                    </th>
                    <th>
                      <span className="text center">Status</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map(({ id, remote_id, address, shipping, email, created, items, status, status_id }) => (
                    <GlobalStyles.TableRow key={id}>
                      <TableCell
                        center
                        input={
                          <Link to={`/order/${id}/`} style={{ maxWidth: 150 }}>
                            {id}
                          </Link>
                        }
                      >
                        <div>({remote_id})</div>
                        <div>{shipping.tracking_method}</div>
                        <div>
                          <a href={shipping.tracking_url} rel="noreferrer" target="_blank">
                            {shipping.tracking}
                          </a>
                        </div>
                      </TableCell>
                      <TableCell center input={<div style={{ minWidth: 80 }}>{created.date}</div>}>
                        <div>{created.time}</div>
                      </TableCell>
                      <TableCell input={address ? address.name : null}>
                        {address ? (
                          <>
                            <div>
                              {address.line1}
                              {address.line2 ? ' - ' + address.line2 : ''} {address.state} {address.zip}{' '}
                              {address.country_code}
                            </div>
                            <div>
                              {email} | {address.phone}
                            </div>
                          </>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {items.length ? (
                          <div
                            style={{
                              maxHeight: 200,
                              overflow: 'scroll',
                              boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px',
                            }}
                          >
                            <GlobalStyles.DataTable>
                              <tbody>
                                {items.length ? (
                                  <div
                                    style={{
                                      maxHeight: 200,
                                      overflow: 'scroll',
                                      boxShadow: 'rgba(67, 56, 93, 0.09) -4px 4px 15px 1px',
                                    }}
                                  >
                                    <GlobalStyles.DataTable>
                                      <tbody>
                                        {items.map(({ id, img, name, sku, qty }) => (
                                          <Styled.TableRow key={id}>
                                            <TableCell
                                              center
                                              input={
                                                <img
                                                  src={img}
                                                  style={{ height: 'auto', maxWidth: 50, maxHeight: 30 }}
                                                />
                                              }
                                            />
                                            <TableCell input={<Link to={`/product/variant/${id}/`}>{sku}</Link>}>
                                              <div>{name}</div>
                                            </TableCell>
                                            <TableCell input={qty} center />
                                          </Styled.TableRow>
                                        ))}
                                      </tbody>
                                    </GlobalStyles.DataTable>
                                  </div>
                                ) : null}
                              </tbody>
                            </GlobalStyles.DataTable>
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell
                        center
                        input={<OrderStatusBox style={{ width: 132, margin: 'auto' }} {...{ status, status_id }} />}
                      />
                    </GlobalStyles.TableRow>
                  ))}
                </tbody>
              </GlobalStyles.DataTable>
            </div>
            {search && !loadedOrders ? <CardLoading text={ordersPlaceholder} error={errorOrders} /> : null}
          </GlobalStyles.FullPageCard>
          <GlobalStyles.FullPageCard>
            <GlobalStyles.CardHeader>
              <GlobalStyles.CardTitle>
                <h3>
                  ASN Results<small>{asns.length} Total</small>
                </h3>
              </GlobalStyles.CardTitle>
            </GlobalStyles.CardHeader>
            <AsnListFiltered asns={asns} />
            {search && !loadedAsns ? <CardLoading text={asnsPlaceholder} error={errorAsns} /> : null}
          </GlobalStyles.FullPageCard>
          <GlobalStyles.FullPageCard>
            <GlobalStyles.CardHeader>
              <GlobalStyles.CardTitle>
                <h3>
                  Product Variant List<small>{variants.length} Total</small>
                </h3>
              </GlobalStyles.CardTitle>
            </GlobalStyles.CardHeader>
            <div style={{ minHeight: 100, maxHeight: 500, overflow: 'scroll' }}>
              <GlobalStyles.DataTable>
                <thead>
                  <tr>
                    <th />
                    <th>
                      <span className="text">Product</span>
                    </th>
                    <th>
                      <span className="text">SKU</span>
                    </th>
                    <th>
                      <span className="text center">On Hand</span>
                    </th>
                    <th>
                      <span className="text center">On Order</span>
                    </th>
                    <th>
                      <span className="text center">Defective</span>
                    </th>
                    <th>
                      <span className="text center">Production</span>
                    </th>
                    <th>
                      <span className="text center">Available</span>
                    </th>
                    <th>
                      <span className="text center">Inbound</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {variants.map((p: any, id: number) => (
                    <ProductRow {...p} key={id} />
                  ))}
                </tbody>
              </GlobalStyles.DataTable>
            </div>
            {search && !loadedVariants ? <CardLoading text={productVariantsPlaceholder} error={errorVariants} /> : null}
          </GlobalStyles.FullPageCard>
        </div>
      </GenericModal>
    </Styled.Search>
  )
}
