import React from 'react'
import { TableCell } from '../../components/component-items/datatable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVial } from '@fortawesome/pro-duotone-svg-icons'

// Components
import { StatusBoxStyled } from '../../components/component-items/status-box'
import GlobalStyles from '../../components/component-items/styles'
import GenericModal from '../../components/component-items/modal'

import automations from './templates-data.json'

export const TemplatesModal = ({
  open,
  setOpen,
  handleUpdateAutomation,
}: {
  open: boolean
  setOpen: (value: React.SetStateAction<boolean>) => void
  handleUpdateAutomation: (
    type: string,
    condition: any,
    event: { category: string; type: string; value: string },
    action: { type: string; value: string }
  ) => void
}) => {
  return (
    <GenericModal heading="Example Automations" show={open} size="lg" onHide={() => setOpen(false)}>
      {automations.length > 0 ? (
        <div style={{ minHeight: 100, width: '100%' }}>
          <GlobalStyles.DataTable>
            <thead>
              <tr>
                <th>
                  <span className="text">Name</span>
                </th>
                <th>
                  <span className="text">Category</span>
                </th>
                <th>
                  <span className="text">Description</span>
                </th>
                <th>
                  <span className="text center">Trigger Event</span>
                </th>
                <th>
                  <span className="text center">Action</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {automations.map(({ id, category, description, name, event, action, condition }: any) => (
                <GlobalStyles.TableRow key={id}>
                  <TableCell input={<span style={{ textTransform: 'capitalize' }}>{name}</span>} />
                  <TableCell center input={<span style={{ textTransform: 'capitalize' }}>{category}</span>} />
                  <TableCell input={<span>{description}</span>} />
                  <TableCell
                    center
                    input={
                      <StatusBoxStyled
                        className={'blue'}
                        style={{ minWidth: 80, margin: 'auto', textTransform: 'capitalize' }}
                      >
                        {event?.category === 'now' ? 'Manual' : event?.category?.replace(/_/g, '\u00A0')}
                      </StatusBoxStyled>
                    }
                  />
                  <TableCell
                    center
                    input={
                      <StatusBoxStyled
                        className={'royalshine'}
                        style={{ minWidth: 80, margin: 'auto', textTransform: 'capitalize' }}
                      >
                        {action.type === 'logical_and' ? 'Multi Action' : action.type?.replace(/_/g, '\u00A0')}
                      </StatusBoxStyled>
                    }
                  />
                  <TableCell
                    center
                    input={
                      <button
                        type="button"
                        onClick={() => {
                          handleUpdateAutomation(category, condition, event, action)
                          setOpen(false)
                        }}
                      >
                        <FontAwesomeIcon icon={faVial} />
                      </button>
                    }
                  />
                </GlobalStyles.TableRow>
              ))}
            </tbody>
          </GlobalStyles.DataTable>
        </div>
      ) : (
        <div style={{ minHeight: 100, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span>No automations available</span>
        </div>
      )}
    </GenericModal>
  )
}
