import styled from 'styled-components'
import { device } from '../../component-items/device'

type BrandProps = {
  navOpen?: boolean
  theme: object
}
const AsideBrand = styled.div<BrandProps>`
  display: flex;
  justify-content: ${(props) => (props.navOpen ? 'space-between' : 'center')};
  align-items: center;
  flex-direction: row;
  padding: 0 25px;
  height: 75px;
  flex: none;
`

const BrandLogo = styled.div<BrandProps>`
  display: ${(props) => (props.navOpen ? 'flex' : 'none')};
  justify-content: flex-begin;
  span {
    color: ${(props) => props.theme.colors.white};
    padding-left: 10px;
    font-size: 16px;
    font-weight: 400;
  }
`

const AsideHamburgerMenu = styled.button`
  display: none;
  @media ${device.laptop} {
    display: block;
  }
`
type MenuProps = {
  submenu?: boolean
  theme: object
}
const MenuItem = styled.div<MenuProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  float: none;
  padding: 0;
  position: relative;
  margin: 0;
  a {
    cursor: pointer;
    min-height: 44px;
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    margin: 0;
    padding: 0;
    text-decoration: none;
    position: relative;
    outline: none;
    padding: ${(props) => (props.submenu ? '0 0 0 45px' : '9px 20px')};
    &:hover,
    &.active,
    &.open {
      background-color: #1f2433;
      transition: background-color 0.3s;
      text-decoration: none;
      cursor: pointer;
      span {
        color: ${(props) => props.theme.colors.white};
      }
    }
    &:hover i,
    &.active i {
      color: ${(props) => props.theme.colors.dodgerBlue};
    }
    span {
      color: ${(props) => (props.submenu ? '#989eb3' : '#c0c4d1')};
      font-weight: 400;
      font-size: 1rem;
      text-transform: initial;
      display: flex;
      align-items: center;
      flex-grow: 1;
      padding: 0;
    }
    i {
      color: #596382;
      display: flex;
      align-items: center;
      line-height: 0;
    }
    .menu__link-icon {
      flex: 0 0 40px;
      font-size: 1.3rem;
      justify-content: center;
    }
    .aside-menu_beta,
    .aside-menu_new {
      border: solid 2px #319a6d;
      border-radius: 10px;
      padding: 2px 8px;
      color: white;
      font-weight: 500;
    }
    .aside-menu_new {
      background: linear-gradient(50deg, #041fa5, #e45454);
    }
  }
`

const MenuBullet = styled.i`
  vertical-align: middle;
  text-align: left;
  flex: 0 0 20px;
  & > span {
    position: absolute;
    background-color: #989eb3;
    width: 5px;
    height: 1px;
  }
`

const MenuSection = styled.div`
  display: flex;
  padding: 0 27px;
  margin: 20px 0 0 0;
  height: 40px;
  h4 {
    font-size: 0.83rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
  }
`

const Submenu = styled.div`
  margin: 0;
  padding: 0;
  ul {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0;
    margin: 0;
    list-style: none !important;
  }
`

const AsideMenu = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.periwinkleGray};
    border-radius: 5px;
  }
  ul {
    margin: 0;
    list-style: none;
    padding: 15px 0;
  }
`

const AsideFooter = styled.div`
  padding: 7px 15px;
  background-color: #1f2231;
  .aside__footer-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    &:hover,
    &.active {
      background-color: #191b27;
    }
  }
  button {
    background-color: unset;
    border: unset;
  }
  i {
    color: #364b8b;
  }
`

const Aside = styled.div<BrandProps>`
  flex: 0 0 auto;
  display: ${(props) => (props.navOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 98;
  width: ${(props) => (props.navOpen ? '260px' : '80px')};
  background-color: #242939;
  flex: auto;
  height: 100%;
  @media ${device.laptop} {
    display: flex;
  }
  .aside-menu_item,
  .aside-menu_beta,
  .aside-menu_new {
    display: ${(props) => (props.navOpen ? 'flex' : 'none')};
  }
  .aside-menu_header {
    h4 {
      display: ${(props) => (props.navOpen ? 'flex' : 'none')};
    }
    &:after {
      ${(props) =>
        props.navOpen
          ? null
          : `
          position: relative;
          top: -0.6em;
          left: 2.5px;
          content: '...';
          font-size: 3em;
        `}
    }
  }
`

export const Styled = {
  Aside,
  AsideBrand,
  BrandLogo,
  AsideHamburgerMenu,
  MenuItem,
  MenuBullet,
  MenuSection,
  Submenu,
  AsideMenu,
  AsideFooter,
}
