import React, { useState, useEffect } from 'react'
import { globalState } from '../../store'

// Components
import CardLoading from '../../components/component-items/loading-popover'
import GlobalStyles from '../../components/component-items/styles'
import { useFetch } from '../../components/component-items/helpers'
import { THSort } from '../../components/component-items/datatable'
import { SearchBox } from '../../components/component-items/search'
import { BatchListCellCustom, BatchListRowStyled, BatchListStyled, DataTable, StatusBox } from './styles'

// Enums
import { OrderStatus } from '../../enums'

import styled from 'styled-components'
import ReactTimeAgo from 'react-time-ago'

type CompanyListCellProps = {
  company?: string
  center?: any
  children?: any
  input?: any
  width?: any
  grading?: string
}

const CompanyListCell = ({ company, input, children, center, grading }: CompanyListCellProps) => (
  <BatchListCellCustom className={company === 'Total' ? 'total' : ''}>
    <div style={{ textAlign: center ? 'center' : 'left' }}>
      <span className="input">{input}</span>
      {grading && (
        <>
          <br />
          <small>({grading})</small>
        </>
      )}
      {children}
    </div>
  </BatchListCellCustom>
)

type CompanyListRowProps = {
  key?: any
  id?: any
  company: string
  overdue: string
  delayedPickup: string
  awaitingArrival: string
  missing: string
  carrierException: string
  rts: string
  grading: string
  setSearch: any
}

export const GraphCardStyled = styled.div`
  .graph__item {
    box-shadow: rgba(69, 65, 78, 0.06) 0px 1px 15px 1px;
    border-radius: 0.5rem;
    padding: 1.4rem;
    margin: 1rem 0 1rem 1rem;
  }
  .title {
    font-weight: 500;
    font-size: 1.1rem;
    color: #48465b;
    padding-bottom: 0rem;
  }
  .stats {
    display: flex;
    align-items: center;
    color: #74788d;
    font-size: 1rem;
    font-weight: 500;
  }
  .info {
    display: flex;
    color: #74788d;
    font-size: 1rem;
    font-weight: 500;
  }
`

const CompanyListRow = ({
  id,
  company,
  overdue,
  delayedPickup,
  awaitingArrival,
  missing,
  carrierException,
  rts,
  grading,
  setSearch,
}: CompanyListRowProps) => {
  const statusUrl = (status: string) => {
    id = id ? id : ''
    const returnTo = `returnto=${encodeURIComponent(
      `/order/?start_date=&end_date=&filter_status=${status}&date_range_type=&page=1&sku=`
    )}`
    const url = `/api/act-as?company=${id}&${returnTo}`
    return url
  }
  return (
    <BatchListRowStyled className={company === 'Total' ? 'total' : ''}>
      <div onClick={company !== 'Total' ? () => setSearch(company) : undefined}>
        <CompanyListCell company={company} grading={grading} input={company} />
      </div>
      <CompanyListCell company={company} input={<StatusBox className="silver">{overdue}</StatusBox>} center />
      <CompanyListCell company={company} input={<StatusBox className="silver">{delayedPickup}</StatusBox>} center />
      <CompanyListCell company={company} input={<StatusBox className="silver">{awaitingArrival}</StatusBox>} center />
      <CompanyListCell company={company} input={<StatusBox className="silver">{missing}</StatusBox>} center />
      <CompanyListCell company={company} input={<StatusBox className="silver">{carrierException}</StatusBox>} center />
      <CompanyListCell company={company} input={<StatusBox className="silver">{rts}</StatusBox>} center />
    </BatchListRowStyled>
  )
}

export const OrderDelays = () => {
  const {
    state: { warehouseActive },
  } = globalState()
  const [sort, setSort] = useState({})
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const defaultQueryParamsString = {
    order_by: Object.keys(sort),
    sort: Object.values(sort),
    q: search,
  }
  const base_url = `/api/warehouse/${warehouseActive.id}/dashboard/outbound_exceptions/`
  const download_url = base_url + '?download=true'
  const list_url =
    base_url +
    `?${Object.entries(defaultQueryParamsString)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')}`
  const { response, loaded, error }: any = useFetch(list_url, {})
  const companies: any = loaded ? response.companies : []
  const lastUpdated = loaded ? response.lastUpdated : null

  useEffect(() => {
    if (loaded && !error) {
      setLoading(!loaded)
    }
  }, [loaded])

  const download = (e: any) => {
    e.preventDefault()
    window.location.href = download_url
  }

  return (
    <BatchListStyled style={{ position: 'relative' }}>
      <div className="datatable__header">
        <div className="datatable__title">
          <h3>Outbound Exceptions</h3>
          <small className="ps-3">
            Last Updated: {lastUpdated ? <ReactTimeAgo date={Date.parse(lastUpdated)} locale="en-US" /> : 'NA'}
          </small>
        </div>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button className="secondary" onClick={download}>
            Download
          </GlobalStyles.Button>
          <SearchBox {...{ search, setSearch, loading, setLoading, page, setPage }} setFocus />
        </GlobalStyles.CardToolbar>
      </div>
      <div className="card-body">
        <DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Company</span>
              </th>
              <THSort center title={'Overdue'} {...{ sort, setSort }} />
              <THSort center title={'Delayed Pickup'} {...{ sort, setSort }} />
              <THSort center title={'Awaiting Arrival'} {...{ sort, setSort }} />
              <THSort center title={'Missing'} {...{ sort, setSort }} />
              <THSort center title={'Carrier Exception'} {...{ sort, setSort }} />
              <THSort center title={'RTS'} {...{ sort, setSort }} />
            </tr>
          </thead>
          <tbody>
            {companies?.map((c: any, id: Number) => <CompanyListRow {...c} setSearch={setSearch} key={id} />)}
          </tbody>
        </DataTable>
      </div>
      {!loaded ? <CardLoading error={error} /> : null}
    </BatchListStyled>
  )
}

export default OrderDelays
