import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

// Components
import { FullTabMenuCard } from '../../components/component-items/tab-menu'
import { Col, Row } from 'react-bootstrap'

// Cards
import { NotificationList } from '../../components/notifications/'
import { NotificationTags } from './tags'
import { Changelog } from './changelog'
import { TabProps } from './types'

// Fontawesome
import { faBell, faHashtag, faStream } from '@fortawesome/pro-duotone-svg-icons'

export const NotificationPage = () => {
  const location = useLocation()
  const tabMapping: TabProps[] = [
    {
      label: 'Activity Feed',
      component: <NotificationList type="user" title="User Activity" />,
      icon: faBell,
    },
    {
      label: 'Changelog',
      component: <Changelog />,
      icon: faStream,
    },
    {
      label: 'Tags',
      component: <NotificationTags />,
      icon: faHashtag,
    },
  ]
  const tabOptions = tabMapping.map((tab) => tab.label)
  const [activeTab, setActiveTab] = useState(tabOptions[0])

  useEffect(() => {
    if (location.hash.includes('changelog')) {
      setActiveTab('Changelog')
    }
  }, [location.hash])

  return (
    <FullTabMenuCard {...{ tabMapping, activeTab, setActiveTab }}>
      <div style={{ minHeight: 500 }}>{tabMapping.find((tab) => tab.label === activeTab)?.component}</div>
    </FullTabMenuCard>
  )
}
