import React from 'react'

export const InventoryConditionOptions = () => (
  <>
    <option value={'all_inventory_items'}>All Inventory Items</option>
    <optgroup label="Inventory Options">
      <option value={'inventory_item_field'}>Inventory Item Field</option>
    </optgroup>
    <optgroup label="Logical Type">
      <option value={'logical_and'}>AND</option>
      <option value={'logical_or'}>OR</option>
      <option value={'logical_not'}>NOT</option>
    </optgroup>
    <optgroup label="Inventory Transaction Options">
      <option value={'inventory_item_any'}>Match ANY Inventory Transactions</option>
      <option value={'inventory_item_all'}>Match ALL Inventory Transactions</option>
      <option value={'inventory_item_none'}>Match No Inventory Transactions</option>
    </optgroup>
  </>
)

export const InventoryEventOptions = () => (
  <>
    <option value={'inventory_created'}>Inventory Item Created</option>
    <option value={'inventory_adjustment'}>Inventory Adjustment</option>
  </>
)

export const InventoryActionOptions = ({ event }: any) => {
  return (
    <>
      <optgroup label="Notification">
        <option value={'send_notification'}>Send Notification</option>
      </optgroup>
      <optgroup label="Logical Type">
        <option value={'logical_and'}>AND</option>
      </optgroup>
    </>
  )
}

export const InventoryFieldTypes = () => {
  return [
    { value: 'id', label: 'Inventory ID', type: 'number', options: [], async: false, actionable: false },
    {
      value: 'product_variant',
      label: 'Product Variant',
      type: 'string',
      options: [],
      async: false,
      actionable: false,
    },
    { value: 'location', label: 'Location', type: 'string', options: [], async: false, actionable: false },
    { value: 'qr_code', label: 'QR Code', type: 'string', options: [], async: false, actionable: false },
    { value: 'is_printed', label: 'Is Printed', type: 'boolean', options: [], async: false, actionable: false },
    { value: 'lot_number', label: 'Lot Number', type: 'string', options: [], async: false, actionable: false },
    {
      value: 'manufacture_date',
      label: 'Manufacture Date',
      type: 'date',
      options: [],
      async: false,
      actionable: false,
    },
    {
      value: 'best_before_date',
      label: 'Best Before Date',
      type: 'date',
      options: [],
      async: false,
      actionable: false,
    },
    { value: 'expiry_date', label: 'Expiry Date', type: 'date', options: [], async: false, actionable: false },
    { value: 'received_date', label: 'Received Date', type: 'date', options: [], async: false, actionable: false },
    { value: 'inventory_type', label: 'Inventory Type', type: 'string', options: [], async: false, actionable: false },
    { value: 'on_hand', label: 'On Hand', type: 'number', options: [], async: false, actionable: false },
    { value: 'reserved', label: 'Reserved', type: 'number', options: [], async: false, actionable: false },
  ]
}
