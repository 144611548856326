import React, { FunctionComponent, useRef, useState } from 'react'
import { globalState } from '../../store'

// Components
import { AddNotificationProps } from './types'
import { notify, usePost } from '../component-items/helpers'
import GlobalStyles from '../component-items/styles'
import GenericModal from '../component-items/modal'
import { MentionComponent } from '../component-items/mentions'
import { DragNDropMany, Thumbnails } from '../component-items/dragndrop'
import NotificationsStyled from './styles'
import { NotificationMessage, formatDate } from './utils'
import { format } from 'date-fns'
import { InputGroup } from 'react-bootstrap'
import Checkbox from '../component-items/checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/pro-duotone-svg-icons'
import { HoverNotes } from '../component-items/hover-notes'
import Webcam from 'react-webcam'

export const AddNotification: FunctionComponent<AddNotificationProps> = ({
  noteModal,
  setNoteModal,
  type,
  id,
  reply,
}) => {
  const {
    state: { csrf, userIsWarehouse },
  } = globalState()
  const mentionRef = useRef<any>(null)
  const [files, setFiles] = useState<File[]>([])
  const [internal, setInternal] = useState(false)

  // Image Capture
  const webcamRef = useRef<Webcam>(null)
  const [showCapture, setShowCapture] = useState(false)

  // Function to capture a screenshot
  const captureScreenshot = () => {
    const imageSrc = webcamRef.current?.getScreenshot() // Get the base64 encoded image data
    const blob = base64ToBlob(imageSrc) // Convert base64 data to Blob
    const file = new File([blob], 'screenshot.jpg', { type: 'image/png' }) // Create a File object

    // Add preview URL and check if file is an image
    Object.assign(file, { preview: imageSrc, is_image: true })

    // Set the captured screenshot in state
    setFiles((files: File[]) => [...files, file])
  }

  // Function to convert base64 data to Blob
  const base64ToBlob = (base64Data: any) => {
    const byteCharacters = atob(base64Data.split(',')[1]) // Remove base64 header
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: 'image/png' })
  }

  const handleParentSubmit = () => {
    if (mentionRef.current) {
      const message = mentionRef.current.getContent()
      if (!message) {
        notify({ title: 'Message Field Required', message: 'Missing notification body', type: 'error' })
        return
      }
      const formData = new window.FormData()
      if (files) {
        files.map((file: any) => formData.append(file.name, file))
      }
      if (reply) {
        formData.append('reply_to', reply.id.toString())
      }
      formData.append('notification_type', type)
      formData.append(type, (id || '').toString())
      formData.append('message', message)
      formData.append('internal', internal.toString())
      usePost(`/api/core/notifications/`, formData, csrf, true, false)
    }
  }

  const styledReplyTo =
    reply && NotificationMessage({ message: reply.message, recipients: reply?.recipients_display_names })

  return (
    <GenericModal
      heading={`Add ${reply ? 'Reply' : 'Notification'}`}
      show={noteModal}
      onHide={() => setNoteModal(false)}
      buttons={
        showCapture ? (
          <>
            <GlobalStyles.Button className="secondary" style={{ minWidth: 125 }} onClick={() => captureScreenshot()}>
              Take Photo
            </GlobalStyles.Button>
            <GlobalStyles.Button style={{ minWidth: 125 }} onClick={() => setShowCapture((c) => !c)}>
              Done
            </GlobalStyles.Button>
          </>
        ) : (
          <>
            <GlobalStyles.Button
              className="secondary"
              style={{ minWidth: 125 }}
              onClick={() => setShowCapture((c) => !c)}
            >
              Capture Photos
            </GlobalStyles.Button>
            <GlobalStyles.Button style={{ minWidth: 125 }} onClick={() => handleParentSubmit()}>
              Submit
            </GlobalStyles.Button>
          </>
        )
      }
    >
      {showCapture ? (
        <>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" width={320} height={240} />
          </div>
          <Thumbnails files={files} setFiles={setFiles} />
        </>
      ) : (
        <>
          <MentionComponent ref={mentionRef} />
          {userIsWarehouse && (
            <NotificationsStyled.TimelineItemStyled style={{ marginTop: '1em', paddingBottom: 0 }}>
              <InputGroup>
                <Checkbox selected={internal} setSelected={(e) => setInternal((i) => !i)} />
                <HoverNotes
                  description="Messages marked with a lock symbol indicate these are internal and not visible by merchants."
                  title={
                    <>
                      <FontAwesomeIcon icon={faLock} color={'black'} style={{ margin: '0 .5em 0 1em' }} />
                      Internal (Only Visible By Warehouse Users)
                    </>
                  }
                />
              </InputGroup>
            </NotificationsStyled.TimelineItemStyled>
          )}
          {reply && (
            <NotificationsStyled.TimelineItemStyled>
              <span className="list__text">
                <span className="list__subitem list__username">Reply To: {reply.created_by_fullname}</span>
                <span>&nbsp;●&nbsp;</span>
                <NotificationsStyled.Tooltip
                  data-title={format(new Date(reply.created), 'MMMM d, yyyy, h:mm a')}
                  style={{ display: 'inline-block' }}
                >
                  <span className="list__subitem">{formatDate(reply.created)}</span>
                </NotificationsStyled.Tooltip>
                <NotificationsStyled.Quote>{styledReplyTo}</NotificationsStyled.Quote>
              </span>
            </NotificationsStyled.TimelineItemStyled>
          )}
          <DragNDropMany {...{ files, setFiles }} />
        </>
      )}
    </GenericModal>
  )
}
