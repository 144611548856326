import React, { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useFetch, usePost, notify } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import { Form, Col, InputGroup, Row } from 'react-bootstrap'
import { DragNDropThumbnail } from '../../../components/component-items/dragndrop'
import GenericModal from '../../../components/component-items/modal'
import { HoverNotes } from '../../../components/component-items/hover-notes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPortrait } from '@fortawesome/pro-duotone-svg-icons'

import { UploadImageModalProps } from './types'

export const OrderInfo = styled.div`
  width: 50%;
  margin: 0 auto 4em;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: #f2f3f8;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  height: 100%;
  min-height: 300px;
  .order-section {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    margin: 0 1em;
    color: #4a485d;
    div {
      width: 100%;
    }
    span {
      margin-left: 1rem;
      float: right;
      font-weight: 500;
      max-width: 60%;
      text-align: right;
      text-overflow: ellipsis;
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    p {
      margin-bottom: 0;
    }
  }
`

const ProductImage = styled.div`
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14em;
  button {
    position: absolute;
    margin: 0;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
  }
  &:hover button,
  &:hover .shade {
    opacity: 1;
  }
  .shade {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.11);
  }
`

export const UploadImageModal = ({ open, setOpen, file, setFile }: UploadImageModalProps) => {
  return (
    <GenericModal
      heading={'Upload Profile Image'}
      show={open}
      size="lg"
      onHide={() => setOpen(false)}
      buttons={
        <GlobalStyles.Button style={{ minWidth: 125 }} onClick={() => setOpen(false)} type="submit" form="profile-form">
          Upload
        </GlobalStyles.Button>
      }
    >
      <div style={{ minHeight: 100, padding: '1em 0 0', width: 600, margin: 'auto' }}>
        <Form.Label>Profile Photo</Form.Label>
        <DragNDropThumbnail {...{ file, setFile }} image_only />
      </div>
    </GenericModal>
  )
}

export const Profile = () => {
  const defaultSettings = {
    timezone: 'US/Pacific',
    isActive: true,
    lastLogin: '',
    dateJoined: '',
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    displayName: '',
    image: '',
  }

  const [settings, setSettings] = useState(defaultSettings)
  const [validated, setValidated] = useState(false)
  const node = useRef<HTMLFormElement>(null)

  const [open, setOpen] = useState(false)
  const [file, setFile] = useState(null)
  const {
    state: { csrf },
  } = globalState()

  const res = useFetch('/api/company/profile_data/', {})
  const loaded = res.loaded
  const resp: any = loaded ? res.response : {}

  const timezones = loaded ? resp.timezones : []

  useEffect(() => {
    if (loaded) {
      setSettings(resp)
    }
  }, [loaded])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    if (node?.current?.checkValidity() === false || !/^[a-zA-Z0-9]+$/.test(settings.displayName)) {
      return
    }
    setValidated(true)

    var formData = new window.FormData()
    // @ts-ignore
    Object.keys(settings).forEach((key) => formData.append(key, settings[key]))
    if (file) {
      formData.append('profile_image', file)
    }

    const result = await usePost('/api/company/profile_data/', formData, csrf, false, false, true)
    if (!result.success && result.error) {
      notify({ type: 'error', message: result.error })
    } else {
      notify({ type: 'success', message: 'User profile successfully updated' })
    }
  }

  return (
    <GlobalStyles.FullPageCard>
      <Form noValidate ref={node} validated={validated} onSubmit={handleSubmit} id="profile-form">
        <GlobalStyles.CardHeader>
          <GlobalStyles.CardTitle>
            <h3>User Profile {settings.isActive ? null : '(Inactive)'}</h3>
          </GlobalStyles.CardTitle>
        </GlobalStyles.CardHeader>
        <div style={{ padding: '2em', maxWidth: 800, margin: 'auto' }}>
          <OrderInfo>
            <ProductImage onClick={() => setOpen(true)}>
              {settings.image ? (
                <img style={{ width: '100%', height: 'auto' }} src={settings.image} />
              ) : (
                <FontAwesomeIcon icon={faPortrait} />
              )}
              <div className="shade" />
              <GlobalStyles.Button className="secondary" type="button">
                Update
              </GlobalStyles.Button>
            </ProductImage>
            <UploadImageModal {...{ open, setOpen, file, setFile }} />
          </OrderInfo>
          <Row>
            <Form.Group as={Col} md="6" controlId="companyName">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Email" value={settings.email} disabled />
              <Form.Control.Feedback type="invalid">Please enter your email.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="supportEmail" className="required">
              <Form.Label>Username</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  required
                  value={settings.username}
                  onChange={(e) => setSettings({ ...settings, username: e.target.value })}
                />
                <Form.Control.Feedback type="invalid">Please choose a username that exists.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row style={{ alignItems: 'baseline' }}>
            <Form.Group as={Col} md="6" controlId="firstName" className="required">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                value={settings.firstName}
                onChange={(e) => setSettings({ ...settings, firstName: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a first name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="lastName" className="required">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                value={settings.lastName}
                onChange={(e) => setSettings({ ...settings, lastName: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">Please provide a last name.</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row style={{ alignItems: 'baseline' }}>
            <Form.Group as={Col} md="6" controlId="displayName">
              <Form.Label style={{ width: '75%' }}>
                <HoverNotes
                  description="This could be your first name, or a nickname — however you'd like people to refer to you on Shipping Tree."
                  title="Display Name"
                />
              </Form.Label>

              <Form.Control
                type="text"
                placeholder="johndoe"
                value={settings.displayName}
                onChange={(e) => setSettings({ ...settings, displayName: e.target.value })}
                isInvalid={!/^[a-z0-9]+$/.test(settings.displayName)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Display name must only contain lowercase letters and numbers
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="timezone">
              <Form.Label>Timezone</Form.Label>
              <Form.Select
                value={settings.timezone}
                onChange={(e) => setSettings({ ...settings, timezone: e.target.value })}
              >
                {timezones.map((t: any) => (
                  <option value={t} key={t}>
                    {t}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Please provide a valid warehouse.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="lastLogin">
              <Form.Label>Last Login</Form.Label>
              <Form.Control type="text" placeholder="Last Login" value={settings.lastLogin} disabled />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="dateJoined">
              <Form.Label>Date Joined</Form.Label>
              <Form.Control type="text" placeholder="Date Joined" value={settings.dateJoined} disabled />
            </Form.Group>
          </Row>
          <GlobalStyles.Button onClick={handleSubmit} style={{ minWidth: 150, margin: '2em 0', float: 'right' }}>
            Update
          </GlobalStyles.Button>
        </div>
      </Form>
      {!res.loaded ? <CardLoading text={res.placeholder} error={res.error} /> : null}
    </GlobalStyles.FullPageCard>
  )
}
