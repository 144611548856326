import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { globalState } from '../../store'

// Components
import { useClick, usePost } from '../component-items/helpers'
import CardLoading from '../component-items/loading-popover'
import GlobalStyles from '../component-items/styles'
import { DropdownItem } from '../component-items/export-tools'

import { format } from 'date-fns'
import Avatar from 'react-avatar'
import { ButtonLoading } from '../component-items/loading-popover'
import { FileIcon, defaultStyles } from 'react-file-icon'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

// Fontenawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { faReply } from '@fortawesome/pro-solid-svg-icons'

import NotificationsStyled from './styles'
import { NotificationProps, filterOptionsProps, ReplyProps } from './types'
import { subscribeToNotifications } from './subscribe-to-notifications'
import { fetchNotifications } from './fetch-notifications'
import { formatDate, NotificationMessage } from './utils'
import { AddNotification } from './add-notification'
import { AdvancedSelect } from '../component-items/advanced-select'
import { faBell, faCheck, faLock } from '@fortawesome/pro-duotone-svg-icons'
import { Link } from 'react-router-dom'
import Pager, { Pages } from '../component-items/pager'

const renderLink = (path: string, label: string, id: number) => (
  <>
    <Link to={`/${path}/${id}/`}>
      <span className="list__subitem list__username" style={{ textDecoration: 'underline', color: '#5867dd' }}>
        {label} {id}
      </span>
    </Link>
    &nbsp;-&nbsp;
  </>
)

const NotificationLink: FunctionComponent<NotificationProps> = ({
  listType,
  notification_type,
  order,
  product_variant,
  project,
  shipnotice,
}) => {
  if (!['user', 'automation'].includes(listType || '')) return null
  switch (notification_type) {
    case 'order':
      return renderLink('order', 'Order', order)
    case 'product_variant':
      return renderLink('product/variant', 'Variant', product_variant)
    case 'project':
      return renderLink('project', 'Project', project)
    case 'shipnotice':
      return renderLink('asn', 'ASN', shipnotice)
    default:
      return null
  }
}

const Notification: FunctionComponent<NotificationProps & ReplyProps> = (props) => {
  const {
    id,
    message,
    system_generated,
    internal,
    created_by_online: online,
    created_by_fullname,
    created,
    created_by_image,
    recipients_display_names: recipients,
    tags,
    is_automation,
    reply_to_message,
    attachments,
    requires_acknowledgement,
    acknowledged,
    action_type,
    setReply,
  } = props
  const {
    state: { csrf },
  } = globalState()
  const [imageLoaded, setImageLoaded] = useState(false)

  // Split the message into words
  const notificationMessage = NotificationMessage({ message, recipients })
  const styledReplyTo = reply_to_message && NotificationMessage({ message: reply_to_message, recipients })
  const [unread, setUnread] = useState(false)

  useEffect(() => {
    setUnread(requires_acknowledgement && !acknowledged)
  }, [acknowledged, requires_acknowledgement])

  const [showOptions, setShowOptions] = useState(false)
  const optionsNode = useRef(null)
  useClick(optionsNode, setShowOptions)

  const handleImageLoad = () => {
    setImageLoaded(true)
  }

  const handleMarkAsRead = () => {
    setUnread(false)
    usePost(`/api/core/notifications/${id}/acknowledge/`, {}, csrf, false, true, false, false)
  }

  return (
    <NotificationsStyled.TimelineItemStyled style={unread ? { backgroundColor: '#f4f5fb' } : {}}>
      <NotificationsStyled.Box />
      <NotificationsStyled.Badge>
        {!imageLoaded && (
          <Avatar
            name={created_by_fullname}
            size="32"
            round="32px"
            textSizeRatio={1.75}
            className={`image-placeholder ${imageLoaded ? 'hide' : ''}`}
          />
        )}
        {created_by_image ? (
          <img
            src={created_by_image}
            alt={created_by_fullname}
            onLoad={handleImageLoad}
            className={`loaded-image ${imageLoaded ? 'show' : ''} ${is_automation ? 'automation' : ''}`}
          />
        ) : null}
        {online ? <NotificationsStyled.Online /> : !is_automation ? <NotificationsStyled.Offline /> : null}
      </NotificationsStyled.Badge>
      {!system_generated && (
        <NotificationsStyled.Ellipsis ref={optionsNode}>
          <div className="ellipsis_container" onClick={() => setShowOptions((s) => !s)}>
            <span className="ellipsis_text">...</span>
          </div>
          {showOptions && (
            <NotificationsStyled.Dropdown
              style={{ minWidth: '10em', boxShadow: 'rgb(76 72 100 / 25%) 0px 0px 50px 0px' }}
            >
              <GlobalStyles.Tooltip
                data-title={`Reply to ${created_by_fullname}`}
                style={{ textTransform: 'capitalize' }}
              >
                <DropdownItem onClick={setReply} icon={faReply} text={'Reply'} />
              </GlobalStyles.Tooltip>
              {unread && (
                <GlobalStyles.Tooltip
                  data-title={`Mark notification by ${created_by_fullname} as read`}
                  style={{ textTransform: 'capitalize' }}
                >
                  <DropdownItem onClick={handleMarkAsRead} icon={faCheck} text={'Read'} />
                </GlobalStyles.Tooltip>
              )}
            </NotificationsStyled.Dropdown>
          )}
        </NotificationsStyled.Ellipsis>
      )}
      <span className="list__text">
        <span className="list__subitem list__username">{created_by_fullname}</span>
        {internal ? (
          <GlobalStyles.Tooltip
            data-title={`Message is only visible by warehouse users`}
            data-width="200px"
            style={{ textTransform: 'capitalize', display: 'inline-block' }}
          >
            <span style={{ padding: '0 5px' }}>
              <FontAwesomeIcon icon={faLock} color={'purple'} />
            </span>
          </GlobalStyles.Tooltip>
        ) : (
          <span style={unread ? { color: '#ff862b' } : {}}>&nbsp;●&nbsp;</span>
        )}
        <NotificationLink {...props} />
        <NotificationsStyled.Tooltip
          data-title={format(new Date(created), 'MMMM d, yyyy, h:mm a')}
          style={{ display: 'inline-block', marginRight: 22 }}
        >
          <span className="list__subitem">{formatDate(created)}</span>
        </NotificationsStyled.Tooltip>
        <br />
        {system_generated && !attachments.length ? (
          <div>{notificationMessage}</div>
        ) : !attachments.length ? (
          <NotificationsStyled.TextBox>{notificationMessage}</NotificationsStyled.TextBox>
        ) : null}
        {attachments?.length > 0 && (
          <NotificationsStyled.FileBox>
            <div style={{ paddingBottom: '1em' }}>{notificationMessage}</div>
            {attachments.map(({ filename, size, extension, url }, id) => {
              const is_image = ['jpg', 'jpeg', 'bmp', 'gif', 'png'].includes((extension || '').toLowerCase())
              return (
                <div className="file" onClick={() => (window.location.href = url)} key={id}>
                  {is_image ? (
                    <div style={{ display: 'inline-block', margin: '0 .75em 0 0' }}>
                      <img src={url} width="40" height="40" style={{ objectFit: 'cover', borderRadius: '5px' }} />
                    </div>
                  ) : (
                    <div style={{ width: 25, display: 'inline-block', margin: '0 .75em 0 0' }}>
                      <FileIcon extension={extension} {...(defaultStyles as any)[extension]} />
                    </div>
                  )}
                  <span style={{ color: 'black' }}>{filename}</span>
                  <span style={{ fontSize: '.8em' }}> - {size}</span>
                </div>
              )
            })}
          </NotificationsStyled.FileBox>
        )}
        {/* TODO - Update this to include whether the action was completed */}
        {action_type && (
          <>
            <ButtonLoading
              className="secondary"
              style={{ margin: '.5em .5em 0 0', width: 'auto', borderColor: 'black', color: 'black' }}
              title="Decline"
            />
            <ButtonLoading
              className="primary"
              style={{ margin: '.5em .5em 0 0', width: 'auto', backgroundColor: 'black' }}
              title="Approve"
            />
          </>
        )}
        {reply_to_message && <NotificationsStyled.Quote>{styledReplyTo}</NotificationsStyled.Quote>}
        {tags?.map(({ color, name }, id) => (
          <NotificationsStyled.NotificationTag color={color} key={id}>
            #{name}
          </NotificationsStyled.NotificationTag>
        ))}
      </span>
    </NotificationsStyled.TimelineItemStyled>
  )
}

export const NotificationList = ({ id, type, title }: { id?: number | string; type: string; title?: string }) => {
  const { state, dispatch } = globalState()
  const [noteModal, setNoteModal] = useState(false)
  const [reply, setReply] = useState<NotificationProps>()
  const [loaded, setLoaded] = useState(false)
  const [notifications, setNotifications] = useState<NotificationProps[]>([])
  const [filterOptions, setFilterOptions] = useState<filterOptionsProps>(state.userActivityOptions)
  const [filterQueryParams, setFilterQueryParams] = useState<string>('')

  const addNotificationType = type === 'user' && reply ? reply.notification_type : type

  // Pagination
  const [page, setPage] = useState(1)
  const pageLimit = 20
  const offset = (page - 1) * pageLimit
  const [total, setTotal] = useState(0)
  const totalPages = loaded && total ? Math.ceil(total / pageLimit) : 1
  const showCount = 'user' === type ? 5 : 3

  const setDefaultFilter = () => {
    dispatch({ type: 'userActivityOptions', userActivityOptions: filterOptions })
  }

  useEffect(() => {
    const newQueryParams = filterOptions
      .filter(({ selected }) => selected)
      .map(({ label, value, type }) =>
        type === 'boolean'
          ? label.toLowerCase().replace(' ', '_') + '=' + String(value)
          : type === 'multi-select'
            ? label.toLowerCase().replace(' ', '_') + '=' + value.map((v: any) => v.value).toString()
            : null
      )
      .join('&')
    if (newQueryParams !== filterQueryParams) {
      setPage(1)
      setFilterQueryParams(newQueryParams)
    }
  }, [filterOptions])

  useEffect(() => {
    setLoaded(false)
    // Fetch notifications for the order on page load
    let url = `/api/core/notifications/${type}_activity/${id}/`
    if (type === 'user') {
      url = `/api/core/notifications/user_activity_feed/?offset=${offset}&${filterQueryParams}`
    } else if (type === 'automation') {
      url = `/api/core/notifications/automation_activity/?offset=${offset}&${filterQueryParams}`
    }

    fetchNotifications(url).then((result) => {
      setLoaded(true)
      setTotal(result['count'] ? result.count : 0)
      setNotifications(result['results'] ? result.results : result)
    })

    // Subscribe to notifications for the order via websocket
    const unsubscribe = subscribeToNotifications(
      type,
      (notification) => {
        // Handle real-time update (e.g., append notification to list)
        setNotifications((prevNotifications) => [notification, ...prevNotifications])
      },
      id
    )

    // Unsubscribe when component unmounts or orderId changes
    return () => {
      unsubscribe()
    }
  }, [id, page, filterQueryParams])

  // Unset reply when modal closes
  useEffect(() => {
    if (!noteModal) {
      setReply(undefined)
    }
  }, [noteModal])

  return (
    <NotificationsStyled.TimelineStyled>
      <GlobalStyles.CardHeader style={{ paddingRight: '12px' }}>
        <GlobalStyles.CardTitle>
          <h3>{title || 'Activity'}</h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          {type === 'user' ? (
            <AdvancedSelect
              placeholder="Filter"
              {...{ filterOptions, setFilterOptions }}
              setDefault={setDefaultFilter}
              width={400}
            />
          ) : type !== 'automation' ? (
            <button style={{ minWidth: 'unset', marginRight: '10px' }} onClick={() => setNoteModal(true)}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          ) : null}
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ display: 'flex', justifyContent: 'center', minHeight: '500px' }}>
        {notifications.length ? (
          <div className="history-body" style={type === 'user' ? { flexGrow: 0.3 } : { flexGrow: 1 }}>
            <div className="history-list" style={type === 'user' ? { margin: '1em' } : {}}>
              {/* @ts-ignore */}
              <TransitionGroup>
                {notifications.map((notification, id) => (
                  /* @ts-ignore */
                  <CSSTransition key={id} timeout={100} classNames="alert">
                    <Notification
                      {...notification}
                      listType={type}
                      setReply={() => {
                        setReply(notification)
                        setNoteModal(true)
                      }}
                    />
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </div>
          </div>
        ) : loaded ? (
          <NotificationsStyled.NoneLeft>
            <FontAwesomeIcon icon={faBell} />
            No Activity!
          </NotificationsStyled.NoneLeft>
        ) : null}
      </div>
      <AddNotification {...{ noteModal, setNoteModal, id, reply }} type={addNotificationType} />
      {['user', 'automation'].includes(type) && (
        <GlobalStyles.CardFooter style={{ margin: 'auto' }}>
          <Pages page={page} setPage={setPage} total={totalPages} show={showCount} />
        </GlobalStyles.CardFooter>
      )}
      {!loaded ? <CardLoading /> : null}
    </NotificationsStyled.TimelineStyled>
  )
}
