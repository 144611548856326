import React, { useState, useEffect } from 'react'
import { usePut } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import Checkbox from '../../../components/component-items/checkbox'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

import { ShippingOptionsModalProps } from './types'

export const ShippingOptionsModal = ({
  order,
  origShippingOptions,
  shippingOptionsModal,
  setShippingOptionsModal,
}: ShippingOptionsModalProps) => {
  const [shippingOptions, setShippingOptions] = useState({
    use_wholesale_prices: false,
    require_signature: false,
    require_adult_signature: false,
    saturday_delivery: false,
    insure: false,
    insurance_value: 0,
    validate_address: true,
  })

  const {
    state: { csrf },
  } = globalState()

  useEffect(() => {
    if (origShippingOptions) {
      setShippingOptions({
        use_wholesale_prices: origShippingOptions.useWholesalePrices,
        require_signature: origShippingOptions.signature,
        require_adult_signature: origShippingOptions.adultSignature,
        saturday_delivery: origShippingOptions.saturdayDelivery,
        insure: origShippingOptions.insure,
        insurance_value: origShippingOptions.insureAmount,
        validate_address: origShippingOptions.validateAddress,
      })
    }
  }, [origShippingOptions])

  return (
    <GenericModal
      heading={'Rate Options'}
      show={shippingOptionsModal}
      onHide={() => setShippingOptionsModal(false)}
      buttons={
        <GlobalStyles.Button
          style={{ minWidth: 125 }}
          onClick={() =>
            usePut(
              `/api/order/${order}/`,
              { ...shippingOptions, insurance_value: shippingOptions.insure ? shippingOptions.insurance_value : 0 },
              csrf
            )
          }
        >
          Save
        </GlobalStyles.Button>
      }
    >
      <div className="search-bar" style={{ padding: 10, justifyContent: 'center', margin: 'auto', width: '50%' }}>
        <InputGroup>
          <Checkbox
            selected={shippingOptions.use_wholesale_prices}
            setSelected={() =>
              setShippingOptions({ ...shippingOptions, use_wholesale_prices: !shippingOptions.use_wholesale_prices })
            }
          />
          <p style={{ padding: '0 1em', lineHeight: '24px' }}>Use Wholesale Prices</p>
        </InputGroup>
        <InputGroup>
          <Checkbox
            selected={shippingOptions.require_signature}
            setSelected={() =>
              setShippingOptions({ ...shippingOptions, require_signature: !shippingOptions.require_signature })
            }
          />
          <p style={{ padding: '0 1em', lineHeight: '24px' }}>Require Signature</p>
        </InputGroup>
        <InputGroup>
          <Checkbox
            selected={shippingOptions.require_adult_signature}
            setSelected={() =>
              setShippingOptions({
                ...shippingOptions,
                require_adult_signature: !shippingOptions.require_adult_signature,
              })
            }
          />
          <p style={{ padding: '0 1em', lineHeight: '24px' }}>Require Adult Signature</p>
        </InputGroup>
        <InputGroup>
          <Checkbox
            selected={shippingOptions.saturday_delivery}
            setSelected={() =>
              setShippingOptions({ ...shippingOptions, saturday_delivery: !shippingOptions.saturday_delivery })
            }
          />
          <p style={{ padding: '0 1em', lineHeight: '24px' }}>Saturday Delivery</p>
        </InputGroup>
        <InputGroup>
          <Checkbox
            selected={shippingOptions.validate_address}
            setSelected={() =>
              setShippingOptions({ ...shippingOptions, validate_address: !shippingOptions.validate_address })
            }
          />
          <p style={{ padding: '0 1em', lineHeight: '24px' }}>Validate Address</p>
        </InputGroup>
        <InputGroup>
          <Checkbox
            selected={shippingOptions.insure}
            setSelected={() => setShippingOptions({ ...shippingOptions, insure: !shippingOptions.insure })}
          />
          <p style={{ padding: '0 1em', lineHeight: '24px' }}>Insure</p>
        </InputGroup>
        {shippingOptions.insure ? (
          <InputGroup>
            <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
            <Form.Control
              type="text"
              value={shippingOptions.insurance_value}
              onChange={(e) => setShippingOptions({ ...shippingOptions, insurance_value: Number(e.target.value) })}
              placeholder="Insurance Amount"
              aria-describedby="inputGroupPrepend"
              required
            />
            <Form.Control.Feedback type="invalid">Please choose an amount for insurance.</Form.Control.Feedback>
          </InputGroup>
        ) : null}
      </div>
    </GenericModal>
  )
}
