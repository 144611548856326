import React, { useState, useEffect, useRef } from 'react'
import { useFetch, usePost, usePut, useDelete } from '../../../components/component-items/helpers'
import { globalState } from '../../../store'

// Components
import CardLoading from '../../../components/component-items/loading-popover'
import GlobalStyles from '../../../components/component-items/styles'
import GenericModal from '../../../components/component-items/modal'
import Checkbox from '../../../components/component-items/checkbox'
import { StatusBoxStyled } from '../../../components/component-items/status-box'
import { TableCell } from '../../../components/component-items/datatable'
import { TableRow } from './styles'
import { Form, InputGroup } from 'react-bootstrap'
import { HoverNotes } from '../../../components/component-items/hover-notes'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/pro-duotone-svg-icons'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

export const AddRule = ({
  currentRule,
  serviceLevelList,
  open,
  setOpen,
}: {
  currentRule: {
    id?: any
    remote_method?: any
    service_level?: any
    forced?: any
    delivery_days_enabled?: any
    delivery_days?: any
    guaranteed_delivery?: any
  }
  serviceLevelList: {
    id: any
    sku: any
    carrier__name: any
    name: any
  }[]
  open: boolean
  setOpen: (open: boolean) => void
}) => {
  const node = useRef<HTMLFormElement>(null)
  const [validated, setValidated] = useState(false)
  const defaultRule = {
    id: null,
    remote_method: '',
    service_level: '',
    forced: false,
    delivery_days_enabled: false,
    delivery_days: 0,
    guaranteed_delivery: false,
  }
  const [rule, setRule] = useState(defaultRule)
  const carrier_grouping = [...new Set(serviceLevelList.map((sl: any) => sl.carrier__name))]

  const {
    state: { csrf, companyId },
  } = globalState()

  useEffect(() => {
    if (Object.keys(currentRule).length === 0 && currentRule.constructor === Object) {
      setRule(defaultRule)
    } else {
      var delivery_days_enabled = false
      if (currentRule.delivery_days) {
        delivery_days_enabled = true
      }
      // @ts-ignore
      setRule({ ...currentRule, ...{ delivery_days_enabled } })
    }
  }, [currentRule])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setValidated(true)

    if (node?.current?.checkValidity() === false) {
      return
    }

    if (rule.id) {
      usePut(`/api/company/${companyId}/shipping-method/${rule.id}/`, { ...rule }, csrf)
    } else {
      usePost(`/api/company/${companyId}/shipping-method/`, { ...rule }, csrf)
    }
  }

  return (
    <GenericModal
      heading={rule.id ? 'Update Shipping Method' : 'Create Shipping Method'}
      show={open}
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={handleSubmit}>{rule.id ? 'Update' : 'Create'}</GlobalStyles.Button>}
    >
      <Form ref={node} validated={validated} onSubmit={handleSubmit}>
        <Form.Group style={{ margin: '2em' }}>
          <Form.Label>Shopping Cart Method</Form.Label>
          <Form.Control
            name="remote_method"
            placeholder="Example: Free Shipping"
            required
            value={rule.remote_method}
            onChange={(e) => setRule({ ...rule, remote_method: e.target.value })}
          />
          <InputGroup style={{ paddingTop: '2em' }}>
            <Checkbox selected={rule.forced} setSelected={() => setRule({ ...rule, forced: !rule.forced })} />
            <p style={{ padding: '0 1em' }}>
              <HoverNotes
                description="You will be notified and the order will be delayed if the desired service level cannot be found."
                title="Force Shipping Method"
              />
            </p>
          </InputGroup>
          <InputGroup>
            <Checkbox
              selected={rule.delivery_days_enabled}
              setSelected={() => setRule({ ...rule, delivery_days_enabled: !rule.delivery_days_enabled })}
            />
            <p style={{ padding: '0 1em', lineHeight: '24px' }}>Use Delivery Days</p>
          </InputGroup>
          {rule.delivery_days_enabled ? (
            <>
              <InputGroup>
                <Checkbox
                  selected={rule.guaranteed_delivery}
                  setSelected={() => setRule({ ...rule, guaranteed_delivery: !rule.guaranteed_delivery })}
                />
                <p style={{ padding: '0 1em', lineHeight: '24px' }}>
                  <GlobalStyles.Tooltip
                    data-title="Selecting this will restrict methods to only those that are guaranteed by the carrier to arrive within this timeframe."
                    style={{ borderBottom: 'dashed 1px' }}
                  >
                    Guaranteed Delivery
                  </GlobalStyles.Tooltip>
                </p>
              </InputGroup>
              <Form.Label style={{ marginTop: '1em' }}>Delivery Days</Form.Label>
              <Form.Control
                type="number"
                value={rule.delivery_days}
                required
                min="1"
                onChange={(e) => setRule({ ...rule, delivery_days: Number(e.target.value) })}
              />
            </>
          ) : (
            <>
              <Form.Label style={{ marginTop: '1em' }}>Official Method / Service Level</Form.Label>
              <Form.Select
                required
                value={rule.service_level}
                onChange={(e) => setRule({ ...rule, service_level: e.target.value })}
              >
                <option value="" hidden>
                  ---SELECT---
                </option>
                {carrier_grouping.map((c, id) => (
                  // @ts-ignore
                  <optgroup label={c} key={id}>
                    {serviceLevelList
                      .filter((sl: any) => sl.carrier__name == c)
                      .map((s: any, id: number) => (
                        <option value={s.id} key={id}>
                          {s.name}
                        </option>
                      ))}
                  </optgroup>
                ))}
              </Form.Select>
            </>
          )}
        </Form.Group>
      </Form>
    </GenericModal>
  )
}

export const ShippingMethodList = () => {
  const {
    state: { csrf, companyId },
  } = globalState()
  const { response: resp, loaded, error, placeholder }: any = useFetch(`/api/company/${companyId}/shipping-method/`, {})
  const shippingMethods = loaded ? resp.methods : []
  const serviceLevelList = loaded ? resp.service_levels : []

  const total = loaded ? shippingMethods.length : 0

  const [open, setOpen] = useState(false)
  const [currentRule, setRule] = useState({})

  function deleteMethod(id: string) {
    useDelete(`/api/company/${companyId}/shipping-method/${id}/`, csrf)
  }

  return (
    <GlobalStyles.FullPageCard>
      <GlobalStyles.CardHeader>
        <GlobalStyles.CardTitle>
          <h3>
            Shipping Method List<small>{total} Total</small>
          </h3>
        </GlobalStyles.CardTitle>
        <GlobalStyles.CardToolbar>
          <GlobalStyles.Button
            onClick={() => {
              setRule({})
              setOpen(true)
            }}
          >
            Add Shipping Method
          </GlobalStyles.Button>
        </GlobalStyles.CardToolbar>
      </GlobalStyles.CardHeader>
      <div style={{ minHeight: 400, padding: '0 1em' }}>
        <GlobalStyles.DataTable>
          <thead>
            <tr>
              <th>
                <span className="text">Shopping Cart Method</span>
              </th>
              <th>
                <span className="text center">Carrier</span>
              </th>
              <th>
                <span className="text center">Service Level</span>
              </th>
              <th>
                <span className="text center">Delivery Days</span>
              </th>
              <th>
                <span className="text center"># Of Orders</span>
              </th>
              <th>
                <span className="text center">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {shippingMethods.map((r: any, id: number) => (
              <TableRow key={id}>
                <TableCell input={r.remote_method} />
                <TableCell
                  center
                  input={r.carrier ? r.carrier : <FontAwesomeIcon icon={faTimes} style={{ color: 'purple' }} />}
                />
                <TableCell
                  center
                  input={
                    r.service_name ? r.service_name : <FontAwesomeIcon icon={faTimes} style={{ color: 'purple' }} />
                  }
                />
                <TableCell
                  center
                  input={
                    r.delivery_days ? r.delivery_days : <FontAwesomeIcon icon={faTimes} style={{ color: 'purple' }} />
                  }
                />
                <TableCell
                  center
                  input={
                    <StatusBoxStyled className={r.order_count ? 'blue' : 'silver'} style={{ width: 100, margin: 'auto' }}>
                      {r.order_count ? r.order_count.toLocaleString() : 0}
                    </StatusBoxStyled>
                  }
                />
                <TableCell
                  center
                  input={
                    <div className="dropdown__container">
                      <button
                        onClick={() => {
                          setRule(r)
                          setOpen(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button onClick={() => deleteMethod(r.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  }
                />
              </TableRow>
            ))}
          </tbody>
        </GlobalStyles.DataTable>
      </div>
      {!loaded ? <CardLoading text={placeholder} error={error} /> : null}
      <AddRule {...{ open, setOpen, currentRule, serviceLevelList }} />
    </GlobalStyles.FullPageCard>
  )
}
