import React, { useState, useEffect } from 'react'
import { globalState } from '../../store'

// Components
import CardLoading from '../../components/component-items/loading-popover'
import GlobalStyles from '../../components/component-items/styles'
import { useFetch } from '../../components/component-items/helpers'
import { THSort } from '../../components/component-items/datatable'
import { SearchBox } from '../../components/component-items/search'
import { StatusBoxButton, BatchListCellCustom, BatchListRowStyled, BatchListStyled, DataTable } from './styles'

type CompanyListCellProps = {
  company?: string
  center?: any
  children?: any
  input?: any
  grading?: string
}

const CompanyListCell = ({ company, input, children, center, grading }: CompanyListCellProps) => (
  <BatchListCellCustom className={company === 'Total' ? 'total' : ''}>
    <div style={{ textAlign: center ? 'center' : 'left' }}>
      <span className="input">
        {input}

        {grading && (
          <>
            <br />
            <small>({grading})</small>
          </>
        )}
      </span>
      {children}
    </div>
  </BatchListCellCustom>
)

type CompanyListRowProps = {
  key?: any
  id?: any
  company: string
  one: number
  two: number
  three: number
  four: number
  five: number
  setSearch: any
  grading?: string
}

const CompanyListRow = ({ id, company, one, two, three, four, five, grading, setSearch }: CompanyListRowProps) => {
  const dayRangeUrl = (start: number, range: number) => {
    id = id ? id : ''
    const date = new Date()
    date.setDate(date.getDate() - start)
    const end_date = date.toLocaleDateString('en-US').replace(/\//g, '-')
    date.setDate(date.getDate() - range)
    const start_date = date.toLocaleDateString('en-US').replace(/\//g, '-')
    const returnTo = `returnto=${encodeURIComponent(
      `/order/?start_date=${start_date}&end_date=${end_date}&filter_status=90,100,110,120,160&date_range_type=maturation_between&page=1&sku=`
    )}`
    const url = `/api/act-as?company=${id}&${returnTo}`
    return url
  }

  return (
    <BatchListRowStyled className={company === 'Total' ? 'total' : ''}>
      <div onClick={company !== 'Total' ? () => setSearch(company) : undefined}>
        <CompanyListCell company={company} grading={grading} input={company} />
      </div>
      <CompanyListCell
        company={company}
        input={
          <StatusBoxButton className="silver" onClick={() => (window.location.href = dayRangeUrl(0, 1))}>
            {one}
          </StatusBoxButton>
        }
        center
      />
      <CompanyListCell
        company={company}
        input={
          <StatusBoxButton className="silver" onClick={() => (window.location.href = dayRangeUrl(2, 0))}>
            {two}
          </StatusBoxButton>
        }
        center
      />
      <CompanyListCell
        company={company}
        input={
          <StatusBoxButton className="silver" onClick={() => (window.location.href = dayRangeUrl(3, 0))}>
            {three}
          </StatusBoxButton>
        }
        center
      />
      <CompanyListCell
        company={company}
        input={
          <StatusBoxButton className="silver" onClick={() => (window.location.href = dayRangeUrl(4, 0))}>
            {four}
          </StatusBoxButton>
        }
        center
      />
      <CompanyListCell
        company={company}
        input={
          <StatusBoxButton className="silver" onClick={() => (window.location.href = dayRangeUrl(5, 90))}>
            {five}
          </StatusBoxButton>
        }
        center
      />
    </BatchListRowStyled>
  )
}

export const AgedOrders = () => {
  const {
    state: { warehouseActive },
  } = globalState()
  const [sort, setSort] = useState({})
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const defaultQueryParamsString = {
    order_by: Object.keys(sort),
    sort: Object.values(sort),
    q: search,
  }
  const url =
    `/api/warehouse/${warehouseActive.id}/dashboard/aged_orders/` +
    `?${Object.entries(defaultQueryParamsString)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')}`
  const { response, loaded, error }: any = useFetch(url, {})
  const companies = loaded ? response.companies : []

  useEffect(() => {
    if (loaded && !error) {
      setLoading(!loaded)
    }
  }, [loaded])

  return (
    <BatchListStyled style={{ position: 'relative' }}>
      <div className="datatable__header">
        <div className="datatable__title">
          <h3>Aged Orders (days)</h3>
        </div>
        <GlobalStyles.CardToolbar>
          <SearchBox {...{ search, setSearch, loading, setLoading, page, setPage }} setFocus />
        </GlobalStyles.CardToolbar>
      </div>
      <div className="card-body">
        <DataTable>
          <thead>
            <tr>
              <THSort title={'Company'} sortKey={'name'} {...{ sort, setSort }} />
              <THSort center title={'1'} sortKey={'one'} {...{ sort, setSort }} />
              <THSort center title={'2'} sortKey={'two'} {...{ sort, setSort }} />
              <THSort center title={'3'} sortKey={'three'} {...{ sort, setSort }} />
              <THSort center title={'4'} sortKey={'four'} {...{ sort, setSort }} />
              <THSort center title={'5+'} sortKey={'five'} {...{ sort, setSort }} />
            </tr>
          </thead>
          <tbody>
            {companies?.map((c: any) => (
              <CompanyListRow
                key={c.id}
                id={c.id}
                company={c.name}
                one={c.one}
                two={c.two}
                three={c.three}
                four={c.four}
                five={c.five}
                grading={c.grading}
                setSearch={setSearch}
              />
            ))}
          </tbody>
        </DataTable>
      </div>
      {!loaded ? <CardLoading error={error} /> : null}
    </BatchListStyled>
  )
}

export default AgedOrders
