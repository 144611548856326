import styled from 'styled-components'
import GlobalStyles from '../../components/component-items/styles'
import { StatusBoxStyled } from '../../components/component-items/status-box'

export const CarouselItemStyled = styled.div`
  .body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .widget__product {
      display: flex;
      align-items: center;
      flex: 2 1;
      .thumb {
        border-radius: 8px;
        overflow: hidden;
        margin-right: 1.25rem;
        flex: 0 0 5rem;
        a {
          background: #f7f8fa;
          justify-content: center;
          width: 5rem;
          height: 5rem;
          align-items: center;
          display: flex;
        }
        i {
          display: flex;
          font-size: 1.3rem;
          color: #48465b;
        }
      }
      .widget__content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        flex: 1 1;
      }
      h3 {
        font-size: 1.3rem;
        font-weight: 500;
        color: #48465b;
        margin-bottom: 0.6rem;
      }
      .desc {
        font-weight: 400;
        color: #74788d;
        padding-right: 0.5rem;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    .widget__data {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .widget-info {
        border-radius: 5px;
        background-color: #f7f8fa;
        text-align: center;
        padding: 0.7rem 1rem;
        margin-left: 0.5rem;
        .title {
          font-size: 1.4rem;
          font-weight: 600;
          color: #48465b;
        }
        .info {
          font-size: 1.4rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors.dodgerBlue} !important;
        }
        .desc {
          font-weight: 400;
          color: #74788d;
          padding-right: 0.5rem;
        }
      }
    }
  }
  .footer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.5rem;
    .progress_bar {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex: 2 1;
      justify-content: space-between;
      .label,
      .value {
        display: inline-flex;
        margin-bottom: 0.5em;
      }
      .progress {
        width: 100%;
      }
    }
    .footer__info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex: 1 1;
      .label {
        margin: 0.25rem 0.5rem 0.25rem 0;
        background-color: rgba(93, 120, 255, 0.1);
        color: ${(props) => props.theme.colors.dodgerBlue};
        font-weight: 600;
        outline: 0;
        vertical-align: middle;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
      }
      .desc {
        font-weight: 500;
        color: #74788d;
      }
    }
    .footer__toolbar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      flex: 1 1;
      a {
        display: inline-block;
        margin: 0.25rem 0 0.25rem 0.5rem;
        text-transform: uppercase;
        background: transparent;
        color: #74788d;
        border: 1px solid #e8ecfa;
        align-items: center;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
        text-align: center;
        font-weight: 600;
        transition: all 0.3s ease;
        &:hover {
          color: ${(props) => props.theme.colors.white};
          background: ${(props) => props.theme.colors.dodgerBlue};
          border-color: ${(props) => props.theme.colors.dodgerBlue};
        }
      }
    }
  }
`

export const AsnStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 25px;
  border-radius: 4px;
  flex-grow: 1;
  height: 250px;
  overflow: hidden;
  .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 2.5rem;
    .label {
      font-weight: 600;
      color: #74788d;
      flex: 1 1;
      text-transform: uppercase;
      font-size: 1.1rem;
    }
    .nav {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      button {
        background-color: ${(props) => props.theme.colors.zircon};
        position: relative;
        width: auto;
        opacity: 1;
        width: 2rem;
        height: 2rem;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        border: 0;
        transition: all 0.3s;
        &:hover {
          background: ${(props) => props.theme.colors.dodgerBlue};
          i {
            color: ${(props) => props.theme.colors.white};
          }
        }
      }
      i {
        font-size: 0.8rem;
        color: #93a2dd;
        transition: all 0.3s;
      }
    }
  }
  .no-issues {
    width: 100%;
    height: 75%;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    div {
      font-size: 2em;
    }
  }
`

export const BatchListCellStyled = styled(GlobalStyles.TableCell)`
  &:first-child {
    padding-left: 25px;
  }
  &:last-child {
    cursor: pointer;
    padding-right: 25px;
  }
`

export const BatchListCellCustom = styled(BatchListCellStyled)`
  background: ${(props) => props.theme.colors.white};
  vertical-align: middle;
  padding: 10px;
  font-size: 1rem;
  transition: all 0.3s ease !important;
  height: 100%;
  &:first-child {
    padding-left: 25px;
  }
  &:last-child {
    padding-right: 25px;
    cursor: pointer;
  }
  button {
    border: 0;
    color: #595d6e;
  }
  .input {
    font-weight: 500 !important;
  }
  label {
    top: 4px;
  }
  .company-name {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  &.total {
    background-color: ${(props) => props.theme.colors.catskillWhite};
  }
  .dropdown__container {
    position: relative;
    > button,
    > a {
      cursor: pointer;
      height: 2.5rem;
      width: 2.5rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      text-align: center;
      border-radius: 0.2rem;
      svg {
        font-size: 1.2rem;
      }
      &:hover {
        background: ${(props) => props.theme.colors.zircon};
        svg {
          color: ${(props) => props.theme.colors.dodgerBlue};
        }
      }
    }
  }
`

export const BatchListRowStyled = styled.tr`
  /* left: 0px; */
  &.active td {
    transition: all 0.3s ease !important;
    background: #f7f8fa;
  }
  &.total {
    background-color: ${(props) => props.theme.colors.catskillWhite};
  }
`

export const DataTable = styled.table`
  max-height: 500px;
  thead {
    th {
      padding: 16px 10px;
      vertical-align: middle;
      padding: 10px;
      span.text {
        cursor: pointer;
        display: block;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #595d6e;
        &.center {
          text-align: center;
        }
      }
      &:first-child {
        padding-left: 25px;
        > span {
          position: relative;
          top: 3px;
        }
      }
      &:last-child {
        padding-right: 25px;
      }
    }
    /*Account for scroll bar on table*/
    padding-right: 3px;
  }
  tbody {
    overflow: scroll;
    position: relative;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bbc2e5;
      border-radius: 5px;
    }
  }
  tr {
    border-bottom: ${(props) => `1px solid ${props.theme.colors.zircon}`};
    &.no-issues {
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 1.5em;
      div {
        font-size: 2em;
      }
    }
  }
`

export const BatchListStyled = styled.div`
  position: relative;
  min-height: 500px;
  .datatable__header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    padding: 0 25px;
    min-height: 60px;
    border-bottom: 1px solid #ebedf2;
    ul.dropdown__selection {
      overflow: hidden;
      width: 100%;
      position: absolute;
      z-index: 10;
      background: ${(props) => props.theme.colors.white};
      list-style: none;
      margin: 0;
      box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
      padding: 1rem 0;
      border-radius: 4px;
      li {
        cursor: pointer;
        padding: 10px 15px;
        font-weight: 400;
        color: #595d6e;
        &:hover,
        &.active {
          background: ${(props) => props.theme.colors.gallery};
        }
      }
    }
    .datatable__title {
      display: flex;
      align-items: center;
      h3 {
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
        font-weight: 500;
        color: #48465b;
        small {
          font-weight: 300;
          padding-left: 1rem;
          font-size: 1rem;
          color: #74788d;
        }
      }
    }
    .datatable__toolbar {
      display: flex;
      align-items: center;
      align-content: flex-end;
    }
  }
  button:last-child {
    margin-right: 0;
  }
  .card-body {
    height: 450px;
    margin-bottom: 25px;
    flex-grow: 1;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    border-radius: 4px;
    .empty-card {
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.5em;
      > div {
        font-size: 2em;
      }
    }
    svg {
      max-height: 500px;
    }
  }
  .datatable__foooter {
    margin: 0;
    padding: 25px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  path {
    outline: none;
  }
  #legend {
    width: 100%;
    text-align: center;
    .legend-gradient {
      margin: 0 auto;
      width: 40%;
      height: 10px;
      background: linear-gradient(to right, rgb(247, 251, 255), rgb(8, 48, 107));
    }
    .legend-values {
      margin: 0 auto;
      width: 40%;
      display: flex;
      justify-content: space-between;
      .legend-val {
        display: inline-flex;
      }
    }
  }
`

export const GraphCardStyled = styled.div`
  .graph__item {
    box-shadow: rgba(69, 65, 78, 0.06) 0px 1px 15px 1px;
    border-radius: 0.5rem;
    padding: 1.4rem;
    margin-top: 3rem;
  }
  .title {
    font-weight: 500;
    font-size: 1.1rem;
    color: #48465b;
    padding-bottom: 0rem;
  }
  .stats {
    display: flex;
    align-items: center;
    color: #74788d;
    font-size: 1rem;
    font-weight: 500;
  }
  .info {
    display: flex;
    color: #74788d;
    font-size: 1rem;
    font-weight: 500;
  }
  .chart {
    position: relative;
    margin-left: 40px;
    width: 50px;
    min-height: 30px;
  }
  .canvas-container {
    position: absolute;
    height: 30px;
    right: 0px;
    left: 0px;
    bottom: 0px;
  }
`

export const GeneralStatisticsStyled = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  .head {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    padding: 0 25px;
    border-bottom: 1px solid #ebedf2;
    min-height: 60px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    h3 {
      display: flex;
      align-items: center;

      margin: 0;
      padding: 0;
      font-size: 1.2rem;
      font-weight: 500;
      color: #48465b;
    }
    .toolbar {
      display: flex;
      align-items: center;
      align-content: flex-end;
      button {
        font-weight: 600;
        background: transparent;
        color: #74788d;
        border: 1px solid #e8ecfa;
        vertical-align: middle;
        align-items: center;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
        margin-left: 1em;
        transition: all 0.3s ease !important;
        &:hover {
          color: ${(props) => props.theme.colors.white};
          background: ${(props) => props.theme.colors.dodgerBlue};
          border-color: ${(props) => props.theme.colors.dodgerBlue};
        }
      }
    }
  }
  .widget__body {
    display: flex;
    flex-grow: 1;
    padding: 0;
    height: 100%;
    flex-direction: row;
    border-radius: 4px;
    overflow: hidden;
    .widget__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
    .widget__content {
      flex-grow: 1;
      padding: 0 25px;
    }
    .main-stats {
      display: flex;
      align-items: center;
      margin-top: 2rem;
    }
    .legend-item {
      color: #74788d;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-right: 2em;
      .legend-color {
        width: 1rem;
        height: 0.35rem;
        border-radius: 0.3rem;
      }
      .legend-name {
        padding-left: 0.6rem;
      }
    }
    .main-chart {
      position: relative;
      width: 100%;
      min-height: 250px;
    }
    .main-chart__container {
      position: absolute;
      height: 180px;
      right: 0px;
      left: 0px;
      bottom: 0px;
    }
  }
`

export const GraphCardSmallStyled = styled.div`
  position: relative;
  height: calc(50% - 20px);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 20px;
  border-radius: 4px;
  .widget-body {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 25px;
    border-radius: 4px;
    flex-grow: 1;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
  }
  .details {
    display: flex;
    flex-direction: column;
  }
  .title {
    color: #48465b;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 0.7rem;
  }
  .desc {
    color: #74788d;
    font-size: 1rem;
    font-weight: 500;
  }
  .total {
    color: #48465b;
    font-size: 1.75rem;
    font-weight: 500;
  }
  .graph-container {
    position: absolute;
    height: 120px;
    right: 0;
    left: 0;
    bottom: 0;
  }
`

export const InventoryListStyled = styled.div`
  position: relative;
  min-height: 450px;
  .datatable__header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    padding: 0 25px;
    min-height: 60px;
    border-bottom: 1px solid #ebedf2;
    .datatable__title {
      display: flex;
      align-items: center;
      h3 {
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
        font-weight: 500;
        color: #48465b;
        small {
          font-weight: 300;
          padding-left: 1rem;
          font-size: 1rem;
          color: #74788d;
        }
      }
    }
    .datatable__toolbar {
      display: flex;
      align-items: center;
      align-content: flex-end;
    }
  }
  button:last-child {
    margin-right: 0;
  }
  .card-body {
    height: 450px;
    margin-bottom: 25px;
    flex-grow: 1;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    border-radius: 4px;
    .empty-card {
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.5em;
      > div {
        font-size: 2em;
      }
    }
    svg {
      max-height: 500px;
    }
  }
  .datatable__foooter {
    margin: 0;
    padding: 25px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
`

export const NotificationItemStyled = styled.div`
  position: relative;
  .section {
    display: flex;
    align-items: center;
    .icon-border {
      background-color: ${(props) => props.theme.colors.white};
      border-top: ${(props) => `8px solid ${props.theme.colors.white}`};
      border-bottom: ${(props) => `8px solid ${props.theme.colors.white}`};
      position: relative;
    }
    .icon {
      background-color: ${(props) =>
        props.color === 'yellow'
          ? 'rgba(255,184,34,.1)'
          : props.color === 'blue'
            ? 'rgba(93,120,255,.1)'
            : 'rgba(29, 201, 183, 0.2)'};
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    i {
      font-size: 1.7rem;
      color: ${(props: any) =>
        props.color === 'yellow'
          ? `${(props: any) => props.theme.colors.mySin}`
          : props.color === 'blue'
            ? `${(props: any) => props.theme.colors.dodgerBlue}`
            : `${(props: any) => props.theme.colors.java}`};
    }
    .datetime {
      margin-left: 1.2rem;
      color: #74788d;
      font-weight: 600;
      font-size: 0.9rem;
    }
  }
  a:hover {
    color: ${(props) => props.theme.colors.dodgerBlue};
  }
  .text {
    text-decoration: none;
    margin-left: 4.4rem;
    color: #595d6e;
    font-weight: 400;
    font-size: 1rem;
    display: block;
  }
  .info {
    padding: 1rem 0 2rem 4.4rem;
    color: #74788d;
    font-weight: 400;
    font-size: 0.9rem;
  }
  &::before {
    content: '';
    width: 4px;
    height: 100%;
    background: #ebedf2;
    left: 1.5rem;
    top: 0;
    position: absolute;
  }
`

export const NotificationsStyled = styled.div`
  .head {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    padding: 0 25px;
    border-bottom: 1px solid #ebedf2;
    min-height: 60px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    h3 {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      font-size: 1.2rem;
      font-weight: 500;
      color: #48465b;
    }
    .toolbar {
      display: flex;
      align-items: stretch;
      ul {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        list-style: none;
      }
      li {
        display: flex;
        align-items: stretch;
        margin-right: 20px;
        margin-bottom: -1px;
        &:last-child {
          margin-right: 0;
        }
      }
      button {
        display: flex;
        align-items: center;
        background-color: transparent;
        color: #595d6e;
        border: 0;
        padding: 12px 0;
        font-size: 1rem;
        font-weight: 500;
        transition: all 0.3s !important;
        border-bottom: 1px solid transparent;
        &:hover,
        &.active {
          color: ${(props) => props.theme.colors.dodgerBlue};
          border-bottom: 1px solid ${(props) => props.theme.colors.dodgerBlue};
        }
      }
    }
  }
  .body {
    height: 450px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    padding: 25px;
    flex-grow: 1;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bbc2e5;
      border-radius: 5px;
    }
  }
`

export const EmptyPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  font-size: 1.5em;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  .img {
    font-size: 2em;
  }
`

export const OrderListStyled = styled.div`
  position: relative;
  .widget__head {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    padding: 0 25px;
    border-bottom: 1px solid #ebedf2;
    min-height: 60px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    h3 {
      margin: 0;
      padding: 0;
      font-size: 1.2rem;
      font-weight: 500;
      color: #48465b;
      display: flex;
      align-items: center;
    }
    .toolbar {
      position: relative;
      display: flex;
      align-items: center;
      button {
        height: 2.5rem;
        width: 2.5rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        background: 0 0;
        color: #93a2dd;
        border: none;
        i {
          font-size: 1.3rem;
        }
        &:hover {
          color: ${(props) => props.theme.colors.dodgerBlue};
          background: ${(props) => props.theme.colors.zircon};
        }
      }
    }
  }
  .widget__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 25px 25px 0;
    border-radius: 4px;
    .summary {
      margin-top: -1rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: flex-end;
      .summary__total {
        font-size: 3rem;
        font-weight: 600;
        color: #48465b;
        margin-right: 1rem;
      }
      .summary__label {
        color: #74788d;
        font-weight: 500;
        margin-bottom: 1rem;
      }
    }
    .progress {
      margin-bottom: 2rem;
    }
  }
  .results {
    height: 350px;
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bbc2e5;
      border-radius: 5px;
    }
  }
`

export const ShippedOrdersListStyled = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  .widget__head {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    padding: 0 25px;
    min-height: 60px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    h3 {
      margin: 0;
      padding: 0;
      font-size: 1.2rem;
      font-weight: 500;
      color: #48465b;
      display: flex;
      align-items: center;
    }
    .toolbar {
      position: relative;
      display: flex;
      align-items: center;
      button {
        height: 2.5rem;
        width: 2.5rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        background: 0 0;
        color: #93a2dd;
        border: none;
        i {
          font-size: 1.3rem;
        }
        &:hover {
          color: ${(props) => props.theme.colors.dodgerBlue};
          background: ${(props) => props.theme.colors.zircon};
        }
      }
    }
  }
  .widget__body {
    height: 100%;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 25px;
    align-items: stretch;
    justify-content: space-between;
    .aside {
      margin-left: 1rem;
      margin-right: 2em;
      width: 100px;
      position: relative;
      display: flex;
      flex-basis: auto;
      align-items: center;
      justify-content: center;
      .label {
        font-size: 2rem;
        font-weight: 500;
        color: #595d6e;
      }
      img {
        width: 100px;
        opacity: 0.08;
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .line-graph {
      position: relative;
      display: inline-flex;
      width: 100%;
      .line-container {
        position: absolute;
        height: 100px;
        right: 0;
        left: 0;
        bottom: 0;
      }
    }
  }
`

export const ReturnListStyled = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  .widget__head {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    padding: 0 25px;
    min-height: 60px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    h3 {
      margin: 0;
      padding: 0;
      font-size: 1.2rem;
      font-weight: 500;
      color: #48465b;
      display: flex;
      align-items: center;
    }
    .toolbar {
      position: relative;
      display: flex;
      align-items: center;
      button {
        height: 2.5rem;
        width: 2.5rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        background: 0 0;
        color: #93a2dd;
        border: none;
        i {
          font-size: 1.3rem;
        }
        &:hover {
          color: ${(props) => props.theme.colors.dodgerBlue};
          background: ${(props) => props.theme.colors.zircon};
        }
      }
    }
  }
  .widget__body {
    height: 100%;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 25px;
    align-items: stretch;
    justify-content: space-between;
    .aside {
      margin-left: 1rem;
      margin-right: 2em;
      width: 100px;
      position: relative;
      display: flex;
      flex-basis: auto;
      align-items: center;
      justify-content: center;
      .label {
        font-size: 2rem;
        font-weight: 500;
        color: #595d6e;
      }
      img {
        width: 100px;
        opacity: 0.08;
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .bar-graph {
      display: flex;
      max-width: 200px;
      flex: 0 0 100%;
      margin-right: 1rem;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      overflow: hidden;
    }
    .bar-total {
      height: 100%;
      display: flex;
      align-items: flex-end;
      transition: all 0.3s ease;
      background-color: ${(props) => props.theme.colors.zircon};
      width: 8px;
      border-radius: 5px;
    }
    .bar-current {
      border-radius: 5px;
      transition: all 0.6s ease;
      background-color: ${(props) => props.theme.colors.dodgerBlue};
      width: 8px;
    }
  }
`

export const ProjectStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 25px;
  border-radius: 4px;
  flex-grow: 1;
  height: 150px;
  overflow: hidden;
  .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 2.5rem;
    .label {
      font-weight: 600;
      color: #74788d;
      flex: 1 1;
      text-transform: uppercase;
      font-size: 1.1rem;
    }
    .nav {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      button {
        background-color: ${(props) => props.theme.colors.zircon};
        position: relative;
        width: auto;
        opacity: 1;
        width: 2rem;
        height: 2rem;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        border: 0;
        transition: all 0.3s;
        &:hover {
          background: ${(props) => props.theme.colors.dodgerBlue};
          i {
            color: ${(props) => props.theme.colors.white};
          }
        }
      }
      i {
        font-size: 0.8rem;
        color: #93a2dd;
        transition: all 0.3s;
      }
    }
  }
  .no-issues {
    width: 100%;
    height: 75%;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    div {
      font-size: 2em;
    }
  }
`

export const NavItemStyled = styled.li`
  margin: 0.5rem;
  box-shadow: rgba(69, 65, 78, 0.06) 0px 1px 15px 1px;
  border-radius: 0.5rem;
  text-align: center;
  &:hover button {
    background: ${(props) => props.theme.colors.gallery};
  }
  &.active button {
    background: ${(props) => props.theme.colors.dodgerBlue};
    i,
    .title {
      color: ${(props) => props.theme.colors.white};
    }
  }
  button {
    min-width: 75px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0.85rem 0.75rem;
    color: #595d6e;
    font-size: 1rem;
    font-weight: 400;
    border: 0;
    margin: 0;
    border-radius: 0.25rem;
  }
  i {
    display: block;
    padding-bottom: 0.5rem;
    margin: 0;
    font-size: 1.3rem;
    color: #a2a5b9;
  }
  .title {
    display: block;
    font-weight: 500;
    color: #74788d;
  }
`

export const WidgetItemStyled = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:first-child {
    margin-top: 0;
  }
  .info {
    width: 60%;
    padding-right: 0.5rem;
    .title {
      font-weight: 500;
      color: #48465b;
      font-size: 1.1rem;
      padding-bottom: 0.3rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .desc {
      overflow-wrap: break-word;
      font-weight: 400;
      color: #74788d;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .stats {
    width: 35%;
    min-width: 35%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    padding-left: 0.75rem;
    .value {
      text-align: right;
      font-weight: 500;
      color: #74788d;
      font-size: 1.1rem;
    }
    .bar {
      padding-left: 1.4rem;
      width: 45%;
      min-width: 45%;
      .progress {
        height: 0.4rem;
      }
      .progress-bar {
        background-color: ${(props) => props.color};
      }
    }
  }
`

export const TechTeamBacklogStyled = styled.div`
  position: relative;
  .head {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    padding: 0 25px;
    border-bottom: 1px solid #ebedf2;
    min-height: 60px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    h3 {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      font-size: 1.2rem;
      font-weight: 500;
      color: #48465b;
    }
    .toolbar {
      display: flex;
      align-items: center;
      align-content: flex-end;
      button {
        height: 2.5rem;
        width: 2.5rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border-radius: 2px;
        color: #93a2dd;
        i {
          font-size: 1.3rem;
        }
        &:hover {
          color: ${(props) => props.theme.colors.dodgerBlue};
          background: ${(props) => props.theme.colors.zircon};
        }
      }
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    padding: 25px;
    flex-grow: 1;
    .list-items {
      overflow: scroll;
      height: 300px;
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background: #bbc2e5;
        border-radius: 5px;
      }
    }
    ul {
      display: flex;
      justify-content: center;
      list-style: none;
      flex-wrap: wrap;
      padding-left: 0;
    }
  }
`

export const StatusBox = styled(StatusBoxStyled)`
  width: 50px;
  margin: auto;
  text-align: center;
  line-height: 1em;
  border: 1px solid #dfe4ff;
  padding: 0.5rem 0;
`


export const StatusBoxButton = styled(StatusBoxStyled)`
  width: 50px;
  margin: auto;
  text-decoration: underline;
  cursor: pointer;
  line-height: 1em;
  border: 1px solid #dfe4ff;
  &:hover {
    color: ${(props) => props.theme.colors.dodgerBlue};
    background-color: ${(props) => props.theme.colors.zircon};
  }
`
