import React, { useState, useRef } from 'react'
import { globalState } from '../../store'

// Cards
import { Automations } from './automations/automation'
import { Schedules } from './schedules/schedule'
import { TemplatesModal } from './templates-modal'

// Components
import GlobalStyles from '../../components/component-items/styles'
import { StickyElement } from '../../components/component-items/helpers'
import { FullTabMenuCard } from '../../components/component-items/tab-menu'
import { MenuOptions } from './styles'

// Cards
import { StatusBar } from './status-bar'

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { faVial, faMagicWandSparkles, faCalendarDay, faInfoSquare } from '@fortawesome/pro-duotone-svg-icons'
import { AutomationList } from './automations'
import { ScheduleList } from './schedules'
import { NotificationList } from '../../components/notifications'

const MenuOption = ({ url, onClick, icon, title, arrow, children, node }: any) => {
  return (
    <li ref={node} onClick={onClick}>
      <a href={url || '#'}>
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="text">{title}</span>
        {arrow ? <FontAwesomeIcon icon={faChevronRight} style={{ color: '#595d6e' }} /> : null}
      </a>
      {children}
    </li>
  )
}

export const Actions = () => {
  const {
    state: { userIsWarehouse, actAs },
  } = globalState()
  const [openTemplatesModal, setOpenTemplatesModal] = useState(false)
  const automationRef = useRef<any>(null)

  const handleUpdateAutomation = (type: any, condition: any, event: any, action: any) => {
    automationRef?.current?.setExample({ type, condition, event, action })
  }

  const tabMapping = [
    {
      label: 'Create Automation',
      component: <Automations ref={automationRef} />,
      listComponent: <AutomationList />,
      icon: faMagicWandSparkles,
    },
    {
      label: 'Create Schedule',
      component: <Schedules />,
      listComponent: <ScheduleList />,
      icon: faCalendarDay,
    },
  ]
  const [activeTab, setActiveTab] = useState('Create Automation')
  return (
    <>
      <StatusBar />
      <div className="row">
        <div className="col-xl-2">
          <StickyElement>
            <MenuOptions>
              <ul>
                <GlobalStyles.Tooltip data-title="Send user to Helpscout">
                  <MenuOption
                    url="https://help.shippingtree.co/article/66-automations-tutorial"
                    icon={faInfoSquare}
                    title="Automation Tutorial"
                  />
                </GlobalStyles.Tooltip>
                <GlobalStyles.Tooltip data-title="Preview Example Automations">
                  <MenuOption onClick={() => setOpenTemplatesModal(true)} icon={faVial} title={'Example Automations'} />
                </GlobalStyles.Tooltip>
              </ul>
            </MenuOptions>
          </StickyElement>
        </div>
        <div className="col-xl-7">
          <FullTabMenuCard {...{ tabMapping, activeTab, setActiveTab }}>
            {tabMapping.find((tab) => tab.label === activeTab)?.component}
          </FullTabMenuCard>
          {tabMapping.find((tab) => tab.label === activeTab)?.listComponent}
        </div>
        <div className="col-xl-3">
          <StickyElement>
            <NotificationList type="automation" />
          </StickyElement>
        </div>
      </div>
      <TemplatesModal open={openTemplatesModal} setOpen={setOpenTemplatesModal} {...{ handleUpdateAutomation }} />
    </>
  )
}
