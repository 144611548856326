import React, { useState, useRef, useEffect, FunctionComponent } from 'react'
import styled from 'styled-components'
import { useLocation, useHistory } from 'react-router-dom'

// Components
import { useClick } from './helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { ToggleButton } from './button-toggle'
import Checkbox from './checkbox'
import GlobalStyles from './styles'
import { TableCell } from './datatable'
import Select from 'react-select'
// @ts-ignore
import AsyncSelect from 'react-select/async'
import { fetchNotifications } from '../notifications/fetch-notifications'

import { AdvancedSelectProps } from './types'

const MultiSelect = styled.div`
  .basic-multi-select > div {
    min-height: 40px;
  }
`

const ButtonDropdownStyled = styled.div`
  display: inline-block;
  margin: 1em;
  .button-container {
    position: relative;
    display: inline-block;
  }
  ul.dropdown__selection {
    overflow: hidden;
    width: 100%;
    position: absolute;
    z-index: 1000;
    background: white;
    list-style: none;
    margin: 0;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    padding: 1rem 0;
    border-radius: 4px;
    li {
      cursor: pointer;
      padding: 10px 15px;
      font-weight: 400;
      color: #595d6e;
      &:hover,
      &.active {
        /* background: #eef1ff; */
      }
    }
  }
  .dropdown__options {
    display: flex;
    align-self: stretch;
    align-items: center;
    svg {
      width: 1em;
      height: 1.5em;
      color: #a7a7a7;
      &:hover {
        color: #777777;
      }
    }
  }
  .button-label {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding-right: 1rem;
  }
  .filter-button {
    cursor: default;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    color: #949494;
    position: relative;
    outline: 0 !important;
    padding: 6px 8px;
    margin: 1px 0;
    border: 1px solid #e2e5ec;
    border-radius: 4px !important;
    .inputs {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
    }
    .multiValue {
      background-color: hsl(0, 0%, 90%);
      border-radius: 2px;
      display: flex;
      margin: 2px;
      min-width: 0;
      box-sizing: border-box;
      width: fit-content;
      .text {
        border-radius: 2px;
        color: hsl(0, 0%, 20%);
        font-size: 85%;
        overflow: hidden;
        padding: 3px;
        padding-left: 6px;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
      }
      .icon {
        align-items: center;
        border-radius: 2px;
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
        box-sizing: border-box;
        &:hover {
          background-color: #ffbdad;
          svg {
            color: #de350b;
          }
        }
      }
    }
    input {
      cursor: default;
      background: none;
      border: none;
      outline: none;
      min-width: 75px;
      width: 75px;
      margin: 0;
      padding: 0;
      flex: 1;
    }
    &:hover {
      border-color: #b0b0b0;
    }
    &:focus,
    &:focus-within,
    &:active {
      color: #212529;
      border: 2px solid #2684ff;
      margin: 0;
      padding: 6px 7px;
      box-shadow: none !important;
    }
  }
`

const Line = styled.span`
  align-self: stretch;
  background-color: #cccccc;
  width: 1px;
  box-sizing: border-box;
  margin: 2px 10px;
`

export const filterOptionsURLParams = (filterOptions: any) => {
  return filterOptions
    .filter(({ selected }: any) => selected)
    .map(({ label, value, type }: any) =>
      type === 'boolean'
        ? label.toLowerCase().replace(' ', '_') + '=' + String(value)
        : type === 'multi-select'
          ? label.toLowerCase().replace(' ', '_') + '=' + value.map((v: any) => v.value).toString()
          : null
    )
    .filter(Boolean)
    .join('&')
}

export const AdvancedSelect: FunctionComponent<AdvancedSelectProps> = ({
  filterOptions,
  setFilterOptions,
  setDefault,
  placeholder,
  width,
  height,
}) => {
  const location = useLocation()
  const history = useHistory()
  let urlParams = new URLSearchParams(location.search)

  const [input, setInput] = useState('')
  const [defaultOptions, setDefaultOptions] = useState([])
  const [isOpen, setOpen] = useState(false)
  const node = useRef(null)
  useClick(node, setOpen)
  const inputRef = useRef<HTMLInputElement>(null)

  const clicked = () => {
    setOpen(!isOpen)
    inputRef?.current?.focus()
  }

  useEffect(() => {
    setDefaultOptions(filterOptions)
  }, [])

  function getValue(option: any, value: any) {
    if (option['type'] === 'multi-select') {
      return value.map((selection: any) => selection.value).toString()
    } else {
      return value.toString()
    }
  }

  const setSelected = (option: any, selected: any) => {
    const existingOptions = filterOptions.filter((option: any) => option.selected == true).length
    const value = getValue(option, option.value)
    const label = option.label.replace(' ', '_').toLowerCase()
    if (selected) {
      urlParams.set(label, value)
      history.push({ search: urlParams.toString() })
    } else {
      if (existingOptions === 1) {
        // Update URL params to indicate no filters applied vs redirect to default filters
        urlParams.set('no_filter', 'true')
      }
      urlParams.delete(label)
      // Required to prevent triggering location useEffect
      if (urlParams.toString() !== location.search.slice(1)) {
        history.push({ search: urlParams.toString() })
      }
    }
    setInput('')
  }

  const setValue = (option: any, value: any) => {
    const label = option.label.replace(' ', '_').toLowerCase()
    const URLValue = getValue(option, value)
    if (option.selected) {
      urlParams.set(label, URLValue)
      history.push({ search: urlParams.toString() })
    } else {
      let newSelection = [...filterOptions]
      let index = filterOptions.indexOf(option)
      newSelection[index] = { ...option, value: value }
      setFilterOptions(newSelection)
    }
    setInput('')
  }

  useEffect(() => {
    let newSelection = [...filterOptions]
    // If the location changes, update filterOptions
    const urlOptions = Object.fromEntries(urlParams.entries())
    const setOptions: any[] = []
    Object.keys(urlOptions).forEach((k) => {
      const option = filterOptions.find((f: any) => f.label.replace(' ', '_').toLowerCase() === k)
      if (option) {
        setOptions.push(option)
      }
    })
    const unsetOptions = filterOptions.filter((option: any) => !setOptions.includes(option))

    if (!setOptions.length && !urlParams.get('no_filter')) {
      // If there are no existing queries, update the URL with the current ones
      filterOptions.map((option: any) => setSelected(option, option.selected))
    } else {
      // Set new selections
      setOptions.map((f) => {
        let value: any = urlOptions[f.label.replace(' ', '_').toLowerCase()]
        if (f['type'] === 'boolean') {
          value = value.toLowerCase() == 'true'
        } else if (f['type'] === 'multi-select') {
          value = value
            .split(',')
            .map(
              (selection: any) => f.options.find((val: any) => val.value.toString() === selection.toString()) || null
            )
          value = value.filter((v: any) => v !== null)
        }
        let index = filterOptions.indexOf(f)
        newSelection[index] = { ...f, selected: true, value: value }
      })
      // Anything not listed, unselect
      unsetOptions.map((f: any) => {
        let index = filterOptions.indexOf(f)
        newSelection[index] = { ...f, selected: false }
      })
      setFilterOptions(newSelection)
    }
  }, [location])

  return (
    <ButtonDropdownStyled>
      <div className="button-container" ref={node}>
        <button
          style={{ width: width ? width : 300, height: height ? height : 'auto', minHeight: 40 }}
          type="button"
          title="Filter"
          className="filter-button"
          onClick={clicked}
        >
          <div className="inputs" style={{ overflow: 'hidden' }}>
            {filterOptions
              .filter(({ selected }: any) => selected)
              .map((option: any, id: any) =>
                option.type === 'boolean' ? (
                  <div className="multiValue" key={id}>
                    <div className="text">
                      {option.label}={option.value ? 'True' : 'False'}
                    </div>
                    <div
                      className="icon"
                      onClick={() => {
                        setSelected(option, false)
                        setOpen(false)
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </div>
                ) : ['multi-select', 'select'].includes(option.type) ? (
                  <div className="multiValue" key={id}>
                    <div className="text">
                      {option.label}=
                      {option.type === 'multi-select' ? (
                        <>[{option.value.map((v: any) => v.label).toString()}]</>
                      ) : (
                        option.value.label
                      )}
                    </div>
                    <div className="icon">
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => {
                          setSelected(option, false)
                          setOpen(false)
                        }}
                      />
                    </div>
                  </div>
                ) : null
              )}
            <input
              className="button-label"
              placeholder={
                filterOptions.filter(({ selected }: any) => selected).length ? '' : placeholder ? placeholder : 'Filter'
              }
              ref={inputRef}
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          </div>
          <div className="dropdown__options">
            {filterOptions.length ? (
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() =>
                  setFilterOptions((filterOptions: any) =>
                    filterOptions.map((option: any) => ({ ...option, selected: false }))
                  )
                }
              />
            ) : null}
            <Line />
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </button>
        {isOpen ? (
          <ul className="dropdown__selection" style={{ overflow: 'visible' }}>
            {setDefault ? (
              <>
                <li style={{ display: 'flex', justifyContent: 'space-evenly', padding: '0em .5em' }}>
                  <GlobalStyles.Button
                    className="royal"
                    style={{
                      height: '2.5em',
                      width: '-webkit-fill-available',
                      minWidth: '10em',
                      padding: 0,
                      lineHeight: 'unset',
                    }}
                    onClick={() => {
                      setOpen(false)
                      setDefault()
                    }}
                  >
                    Set Default
                  </GlobalStyles.Button>
                  <GlobalStyles.Button
                    className="secondary"
                    style={{
                      height: '2.5em',
                      width: '-webkit-fill-available',
                      minWidth: '10em',
                      padding: 0,
                      lineHeight: 'unset',
                    }}
                    onClick={() => {
                      setOpen(false)
                      setFilterOptions(defaultOptions)
                    }}
                  >
                    Reset
                  </GlobalStyles.Button>
                </li>
                <hr style={{ marginBottom: 0 }} />
              </>
            ) : null}
            <GlobalStyles.DataTable style={{ width: '100%' }}>
              <tbody>
                {filterOptions
                  .filter(({ label }: any) => !input || label.toLowerCase().includes(input.toLowerCase()))
                  .map((option: any, id: number) => {
                    // Async promise options
                    const promiseOptions = async (inputValue: string) => {
                      let result = await fetchNotifications(option.dataUrl + `?search=${inputValue}`)
                      result = result['results'] ? result.results : result
                      // Update filter options before returning
                      if (result.length) {
                        setFilterOptions((filterOptions: any) => {
                          let newSelection = [...filterOptions]
                          let index = filterOptions.indexOf(option)
                          newSelection[index] = { ...option, options: result }
                          return newSelection
                        })
                      }
                      return result
                    }
                    return option.type === 'boolean' ? (
                      <GlobalStyles.TableRow key={id}>
                        <TableCell
                          center
                          style={{ padding: '5px 5px 0' }}
                          input={
                            <Checkbox
                              selected={option.selected}
                              setSelected={() => setSelected(option, !option.selected)}
                            />
                          }
                        />
                        <TableCell style={{ padding: '5px 5px' }} input={option.label} />
                        <TableCell style={{ padding: '5px 5px' }}>
                          <ToggleButton toggle={option.value} setToggle={() => setValue(option, !option.value)} />
                        </TableCell>
                      </GlobalStyles.TableRow>
                    ) : option.type === 'multi-select' ? (
                      option.async ? (
                        <GlobalStyles.TableRow key={id}>
                          <TableCell
                            center
                            style={{ padding: '5px 5px 0' }}
                            input={
                              <Checkbox
                                selected={option.selected}
                                setSelected={() => setSelected(option, !option.selected)}
                              />
                            }
                          />
                          <TableCell style={{ padding: '5px 5px' }} input={option.label} />
                          <TableCell style={{ padding: '5px 5px' }}>
                            <MultiSelect>
                              <AsyncSelect
                                cacheOptions
                                defaultOptions={true}
                                loadOptions={promiseOptions}
                                value={option.value}
                                onChange={(e: any) => setValue(option, e)}
                                closeMenuOnSelect={false}
                                isMulti
                                className="basic-multi-select"
                              />
                            </MultiSelect>
                          </TableCell>
                        </GlobalStyles.TableRow>
                      ) : (
                        <GlobalStyles.TableRow key={id}>
                          <TableCell
                            center
                            style={{ padding: '5px 5px 0' }}
                            input={
                              <Checkbox
                                selected={option.selected}
                                setSelected={() => setSelected(option, !option.selected)}
                              />
                            }
                          />
                          <TableCell style={{ padding: '5px 5px' }} input={option.label} />
                          <TableCell style={{ padding: '5px 5px' }}>
                            <MultiSelect>
                              <Select
                                options={option.options}
                                value={option.value}
                                onChange={(e: any) => setValue(option, e)}
                                closeMenuOnSelect={false}
                                isMulti
                                className="basic-multi-select"
                              />
                            </MultiSelect>
                          </TableCell>
                        </GlobalStyles.TableRow>
                      )
                    ) : option.type === 'select' ? (
                      <GlobalStyles.TableRow key={id}>
                        <TableCell
                          center
                          style={{ padding: '5px 5px 0' }}
                          input={
                            <Checkbox
                              selected={option.selected}
                              setSelected={() => setSelected(option, !option.selected)}
                            />
                          }
                        />
                        <TableCell style={{ padding: '5px 5px' }} input={option.label} />
                        <TableCell style={{ padding: '5px 5px' }}>
                          <MultiSelect>
                            <Select
                              options={option.options}
                              value={option.value}
                              onChange={(e: any) => setValue(option, e)}
                              closeMenuOnSelect={false}
                              className="basic-multi-select"
                            />
                          </MultiSelect>
                        </TableCell>
                      </GlobalStyles.TableRow>
                    ) : null
                  })}
              </tbody>
            </GlobalStyles.DataTable>
          </ul>
        ) : null}
      </div>
    </ButtonDropdownStyled>
  )
}
