import React from 'react'

// Components
import GlobalStyles from '../../../components/component-items/styles'
import { AsnRow } from '../../../pages/asns'

export const AsnListFiltered = ({ asns }: any) => (
  <div style={{ minHeight: 100, maxHeight: 500, overflow: 'scroll' }}>
    <GlobalStyles.DataTable>
      <thead>
        <tr>
          <th>
            <span className="text center">Details</span>
          </th>
          <th>
            <span className="text center">Items</span>
          </th>
          <th>
            <span className="text center">Status</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {asns.map((a: any, id: number) => (
          <AsnRow {...a} key={id} />
        ))}
      </tbody>
    </GlobalStyles.DataTable>
  </div>
)
