import React, { useEffect, FunctionComponent } from 'react'
import { useFetch, notify } from '../../../../components/component-items/helpers'

// Components
import CardLoading from '../../../../components/component-items/loading-popover'
import GlobalStyles from '../../../../components/component-items/styles'
import { Form } from 'react-bootstrap'
import { StatusBoxStyled } from '../../../../components/component-items/status-box'
// @ts-ignore
import { format } from 'sql-formatter'
import GenericModal from '../../../../components/component-items/modal'
import { AsnListFiltered } from '../../../asns/asn/asn-list'
import { OrderListFiltered } from '../../../warehouse/batches/batch/order-list'
import { AutomationPreviewProps } from './types/'

export const AutomationPreview: FunctionComponent<AutomationPreviewProps> = ({
  open,
  setOpen,
  category,
  condition,
  event,
  action,
}) => {
  const { response, loaded, error } = useFetch(open ? '/api/core/automation/preview/' : '', {
    method: 'POST',
    body: JSON.stringify({ ...{ category, condition, event, action }, preview: true }),
  })

  useEffect(() => {
    if (loaded && error) {
      notify({ type: 'error', message: error })
    }
  }, [response])

  const orders = loaded ? response!['orders'] : []
  const asns = loaded ? response!['asns'] : []
  const returns = loaded ? response!['returns'] : []
  const query = loaded ? response!['query'] : ''

  const filtered = loaded ? response!['filtered'] : 0
  const total = loaded ? response!['total'] : 0

  let formattedQuery = ''
  try {
    formattedQuery = query ? format(query, { language: 'postgresql' }) : ''
  } catch (error) {
    console.error('SQL formatting error:', error)
  }

  return (
    <GenericModal
      heading={
        <span>
          Automation Preview{' '}
          <small>
            {filtered} out of {total} Total
          </small>
        </span>
      }
      show={open}
      size="xl"
      onHide={() => setOpen(false)}
      buttons={<GlobalStyles.Button onClick={() => setOpen(false)}>Close</GlobalStyles.Button>}
    >
      <div style={{ minHeight: 500, padding: '0 1em 2em' }}>
        <Form.Label style={{ margin: '1em' }}>Results (Showing Max 100)</Form.Label>
        {orders?.length > 0 ? (
          <OrderListFiltered orders={orders} />
        ) : asns?.length > 0 ? (
          <AsnListFiltered asns={asns} />
        ) : returns?.length > 0 ? (
          <AsnListFiltered asns={returns} />
        ) : null}
        {query ? (
          <>
            <Form.Label style={{ margin: '1em' }}>SQL Query</Form.Label>
            <StatusBoxStyled
              className="silver"
              style={{ margin: '0 0.9em', whiteSpace: 'pre-line', userSelect: 'all' }}
            >
              <pre>{formattedQuery}</pre>
            </StatusBoxStyled>
          </>
        ) : null}
      </div>
      {!loaded ? <CardLoading error={error} /> : null}
    </GenericModal>
  )
}
