import styled from 'styled-components'
import { BackgroundLayer } from '../../components/component-items/status-bar'
import { Form } from 'react-bootstrap'

export const MenuOptions = styled(BackgroundLayer)`
  flex-direction: column;
  ul {
    display: block;
    padding: 0.5rem 0;
    margin: 10px 0;
    list-style: none;
    border: 0;
  }
  li {
    position: relative;
    a {
      transition: all 0.3s;
      display: flex;
      flex-grow: 1;
      align-items: center;
      padding: 0.75rem 1rem 0.75rem 1.5rem;
      &:hover {
        background-color: #f7f8fa;
      }
      .icon {
        flex: 0 0 30px;
        font-size: 1.25rem;
        color: #595d6e;
      }
      .text {
        font-weight: 500;
        flex-grow: 1;
        font-size: 1rem;
        color: #595d6e;
      }
    }
  }
`

export const SettingsContainer = styled(BackgroundLayer)`
  justify-content: space-between;
  padding: 1em;
  margin-bottom: 0;
  height: 100%;
  .order-section {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    margin: 0 1em;
    color: #4a485d;
    div {
      width: 100%;
    }
    span {
      margin-left: 1rem;
      float: right;
      font-weight: 500;
      max-width: 60%;
      text-align: right;
      text-overflow: ellipsis;
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    p {
      margin-bottom: 0;
    }
  }
  .return-empty {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 1.5em;
    div {
      font-size: 1em;
    }
  }
`

export const Label = styled(Form.Label)`
  margin-top: 1em;
`
