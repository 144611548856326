import React, { useState, useEffect } from 'react'
import { globalState } from '../../store'

// Components
import CardLoading from '../../components/component-items/loading-popover'
import GlobalStyles from '../../components/component-items/styles'
import { useFetch } from '../../components/component-items/helpers'
import { THSort } from '../../components/component-items/datatable'
import { SearchBox } from '../../components/component-items/search'
import { BatchListCellCustom, BatchListRowStyled, BatchListStyled, DataTable, StatusBoxButton } from './styles'

// Enums
import { OrderStatus } from '../../enums'

type CompanyListCellProps = {
  company?: string
  center?: any
  children?: any
  input?: any
  width?: any
  grading?: string
}

const CompanyListCell = ({ company, input, children, center, grading }: CompanyListCellProps) => (
  <BatchListCellCustom className={company === 'Total' ? 'total' : ''}>
    <div style={{ textAlign: center ? 'center' : 'left' }}>
      <span className="input">{input}</span>
      {grading && (
        <>
          <br />
          <small>({grading})</small>
        </>
      )}
      {children}
    </div>
  </BatchListCellCustom>
)

type CompanyListRowProps = {
  key?: any
  id?: any
  company: string
  received: number
  processing: number
  picked: number
  packed: number
  stuck: number
  grading: string
  setSearch: any
}

const CompanyListRow = ({
  id,
  company,
  received,
  processing,
  picked,
  packed,
  stuck,
  grading,
  setSearch,
}: CompanyListRowProps) => {
  const statusUrl = (status: string) => {
    id = id ? id : ''
    const returnTo = `returnto=${encodeURIComponent(
      `/order/?start_date=&end_date=&filter_status=${status}&date_range_type=&page=1&sku=`
    )}`
    const url = `/api/act-as?company=${id}&${returnTo}`
    return url
  }
  return (
    <BatchListRowStyled className={company === 'Total' ? 'total' : ''}>
      <div onClick={company !== 'Total' ? () => setSearch(company) : undefined}>
        <CompanyListCell company={company} grading={grading} input={company} />
      </div>
      <CompanyListCell
        company={company}
        input={
          <StatusBoxButton className="silver" onClick={() => (window.location.href = statusUrl(`${OrderStatus.Received}`))}>
            {received}
          </StatusBoxButton>
        }
        center
      />
      <CompanyListCell
        company={company}
        input={
          <StatusBoxButton className="silver" onClick={() => (window.location.href = statusUrl(`${OrderStatus.Processing}`))}>
            {processing}
          </StatusBoxButton>
        }
        center
      />
      <CompanyListCell
        company={company}
        input={
          <StatusBoxButton className="silver" onClick={() => (window.location.href = statusUrl(`${OrderStatus.Picked}`))}>
            {picked}
          </StatusBoxButton>
        }
        center
      />
      <CompanyListCell
        company={company}
        input={
          <StatusBoxButton className="silver" onClick={() => (window.location.href = statusUrl(`${OrderStatus.Packed}`))}>
            {packed}
          </StatusBoxButton>
        }
        center
      />
      <CompanyListCell
        company={company}
        input={
          <StatusBoxButton
            className="silver"
            onClick={() =>
              (window.location.href = statusUrl(`${OrderStatus.LabelPurchaseFail},${OrderStatus.RateLimitExceeded}`))
            }
          >
            {stuck}
          </StatusBoxButton>
        }
        center
      />
    </BatchListRowStyled>
  )
}

export const CompanyProgress = () => {
  const {
    state: { warehouseActive },
  } = globalState()
  const [sort, setSort] = useState({})
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const defaultQueryParamsString = {
    order_by: Object.keys(sort),
    sort: Object.values(sort),
    q: search,
  }
  const url =
    `/api/warehouse/${warehouseActive.id}/dashboard/company_progress/` +
    `?${Object.entries(defaultQueryParamsString)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')}`
  const { response, loaded, error }: any = useFetch(url, {})
  const companies = loaded ? response.companies : []

  useEffect(() => {
    if (loaded && !error) {
      setLoading(!loaded)
    }
  }, [loaded])

  return (
    <BatchListStyled style={{ position: 'relative' }}>
      <div className="datatable__header">
        <div className="datatable__title">
          <h3>Company Progress</h3>
        </div>
        <GlobalStyles.CardToolbar>
          <SearchBox {...{ search, setSearch, loading, setLoading, page, setPage }} setFocus />
        </GlobalStyles.CardToolbar>
      </div>
      <div className="card-body">
        <DataTable>
          <thead>
            <tr>
              <THSort title={'Company'} sortKey={'name'} {...{ sort, setSort }} />
              <THSort center title={'Received'} {...{ sort, setSort }} />
              <THSort center title={'Processing'} {...{ sort, setSort }} />
              <THSort center title={'Picked'} {...{ sort, setSort }} />
              <THSort center title={'Packed'} {...{ sort, setSort }} />
              <THSort center title={'Stuck'} {...{ sort, setSort }} />
            </tr>
          </thead>
          <tbody>
            {companies?.map((c: any, id: number) => (
              <CompanyListRow
                id={c.id}
                company={c.company}
                grading={c.grading}
                received={c.received}
                processing={c.processing}
                picked={c.picked}
                packed={c.packed}
                stuck={c.stuck}
                key={id}
                setSearch={setSearch}
              />
            ))}
          </tbody>
        </DataTable>
      </div>
      {!loaded ? <CardLoading error={error} /> : null}
    </BatchListStyled>
  )
}

export default CompanyProgress
